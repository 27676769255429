import { GetJSON } from '../../sharedModules/api/apiUtils';
import { getSearchAPIEndpoint } from '../../utils/getSearchAPIEndpoint';
import { Localisation, Translations } from '../types/Localiser';

import defaultSite from './sites/data/defaultSite';
import type { Site } from './sites/types/Site';

export default class Localiser {
  private territory = '';

  public articleLanguage = '';

  public language = '';

  private site: Site;

  public widgets: Record<string, string> = {};

  public data: Record<string, string> = {};

  constructor(language: string, territory: string, site: Site = defaultSite) {
    this.territory = territory || 'US';
    this.articleLanguage = language;
    this.language = this.getLanguage(language);
    this.site = site;
  }

  async load(getJSON: GetJSON<Localisation>): Promise<void> {
    const { widgets, data } = await this.getLocalisation(getJSON);
    this.widgets = widgets;
    this.data = data;
  }

  async getLocalisation(getJSON: GetJSON<Localisation>): Promise<Translations> {
    const { language, site } = this;
    const response = await getJSON(`${getSearchAPIEndpoint(site)}/translations.php`, {
      language,
    });
    if (!response.ok) {
      throw new Error("Couldn't fetch translations");
    }
    return response.data.translations;
  }

  getLanguage(language = ''): string {
    const { territory } = this;

    // Language should match the correct format. E.g. "en" or "en-GB" but not "EN_GB"
    if (language && language.search(/(^\w{2}$)|(\w{2}-\w{2})/g) >= 0) {
      return language && language.indexOf('-') === -1 && territory
        ? `${language}-${territory}`
        : language;
    }
    return 'en-US';
  }

  translate(key: string, replacements: string[] = []): string {
    const { widgets, data } = this;

    /* Use the translation for the language or return the text unchanged. The key will
    be the real text for API data translations (e.g. editorial promo flags) */
    let translatedText = ((): string => {
      if (widgets && widgets[key]) {
        return widgets[key];
      }
      if (data && data[key]) {
        return data[key];
      }
      return key;
    })();

    /* Add the real values we don't want to translate back again & remove
    the '{{' '}}' around the value */
    replacements.forEach((value) => {
      translatedText = translatedText.replace('*', value);
    });

    return translatedText;
  }

  /**
   * Set the translations for unit tests
   */
  setTranslations(translations: Translations): void {
    this.data = translations.data;
    this.widgets = translations.widgets;
  }
}
