import React, { useEffect } from 'react';

import { sendRewardActivity } from '../../../../../../sharedModules/api/reward/sendRewardActivity';
import { ProductTypes } from '../../../../../../types/ProductType';
import { Deal } from '../../../../../types/Deal';
import { useHawkWidgetContext } from '../../../HawkWidget/HawkWidgetProviderContext';
import { VoucherCode } from '../../../HawkWidget/model';
import { isSignupFirstDeal } from '../modules/signupFirst';

import { Error } from './Error';
import { Loading } from './Loading';
import { getActions } from './reducer/getActions';
import { reducer, getInitialState } from './reducer/reducer';
import { Signup } from './Signup';
import { Audience } from './types';
import { Verification } from './Verification';
import { VultureReveal } from './VultureReveal';

interface VulturePopupProps {
  popupDeal: Deal;
  setPopupDeal: (deal: Deal | null) => void;
  uniqueCodeData: string;
  voucherCode: VoucherCode;
  setUniqueCodeData: (value: string) => void;
  setRevealedPopupMatchId: (value: number) => void;
}

const audiences = {
  students: {
    regex: /\.edu$|\.ac\.uk$/,
    noun: 'student',
  },
  nhs: {
    regex:
      /@[a-z]+\.nhs\.uk$|@nhs\.uk$|@nhs\.net$|@nhs\.scot$|@phs\.scot$|@hscni\.net$|@doctors\.net\.uk$/,
    noun: 'health worker',
  },
};

export const VulturePopup: React.FC<VulturePopupProps> = ({
  popupDeal,
  setPopupDeal,
  uniqueCodeData,
  setUniqueCodeData,
  voucherCode,
  setRevealedPopupMatchId,
}: VulturePopupProps) => {
  const { site, translate } = useHawkWidgetContext();
  const isReward = popupDeal.product_type === ProductTypes.rewards;
  const isSignupFirst = isSignupFirstDeal(popupDeal);
  const audience: Audience = popupDeal.offer.audience ? audiences[popupDeal.offer.audience] : null;

  const [state, dispatch] = React.useReducer(reducer, getInitialState());
  const actions = getActions(dispatch, translate);

  const isUserInRequiredAudience =
    audience && state.user?.email && state.user.email.match(audience.regex);

  useEffect(() => {
    if (isSignupFirst && state.isSessionLoaded === false) {
      actions.getSession();
    }
  }, [isSignupFirst]);

  useEffect(() => {
    if (state.user?.id && state.user?.email && isReward)
      sendRewardActivity(
        state.user.id,
        state.user.email,
        site,
        state.sessionId,
        popupDeal.product_key,
      );
  }, [state.user, site, state.sessionId, popupDeal.product_key]);

  if (isSignupFirst) {
    if (!state.user) {
      if (!state.isSessionLoaded) {
        return <Loading />;
      }

      return (
        <Signup
          actions={actions}
          audience={audience}
          email={state.signupForm.email}
          areTermsAgreed={state.signupForm.terms}
          isLoading={state.signupForm.isLoading}
          error={state.signupForm.error}
          popupDeal={popupDeal}
          site={site}
          translate={translate}
          setPopupDeal={setPopupDeal}
        />
      );
    }

    if (audience) {
      if (!isUserInRequiredAudience) {
        return (
          <Error
            title={translate('voucherAudienceRequiredTitle', [audience.noun])}
            text={translate('voucherAudienceRequiredText', [audience.noun, audience.noun])}
          />
        );
      }

      if (state.user.email && !state.user.isVerified) {
        return (
          <Verification
            siteName={site.name}
            audienceNoun={audience.noun}
            emailVerificationState={state.emailVerificationState}
            translate={translate}
            userEmail={state.user.email}
            actions={actions}
          />
        );
      }
    }
  }

  if (isReward) {
    // We should always have the user object by this point,
    // however there's a chance the session id is missing.
    // This check aids in typing and catching this edge case
    if (!state.sessionId || !state.user) {
      return <Error text={translate('missingKiosqSessionError')} />;
    }
  }

  return (
    <VultureReveal
      popupDeal={popupDeal}
      site={site}
      translate={translate}
      setPopupDeal={setPopupDeal}
      uniqueCodeData={uniqueCodeData}
      voucherCode={voucherCode}
      setUniqueCodeData={setUniqueCodeData}
      setRevealedPopupMatchId={setRevealedPopupMatchId}
    />
  );
};
