export type QueryParams = {
  [key: string]: string | number | boolean | Array<string | number | boolean> | null | undefined;
};

export const encodeQueryString = (params?: QueryParams): string => {
  if (!params) {
    return '';
  }

  const encodePart = (key: string, value: QueryParams[keyof QueryParams]): string[] => {
    if (value === null || value === undefined || value === '') {
      return [];
    }

    const encodedKey = encodeURIComponent(key);

    if (Array.isArray(value)) {
      return value.map((item) => `${encodedKey}[]=${encodeURIComponent(item)}`);
    }

    return [`${encodedKey}=${encodeURIComponent(value)}`];
  };

  const parts = Object.entries(params).flatMap(([key, value]) => encodePart(key, value));

  return parts.length > 0 ? `?${parts.join('&')}` : '';
};
