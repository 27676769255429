import { QueryParams } from '../../../utils/encodeQueryString';
import { getSearchAPIEndpoint } from '../../../utils/getSearchAPIEndpoint';
import { ApiResponse, GetJSON } from '../apiUtils';

interface SeasonalLink {
  id: number;
  url: string;
  link_text: string;
  tags: string[] | null;
}

export interface SeasonalResponse {
  link: SeasonalLink | null;
  area: string;
  battle: string | null;
  version: string;
}

export const createSeasonalResponseGetter = (getJSON: GetJSON<SeasonalResponse>) => {
  return (params?: QueryParams): Promise<ApiResponse<SeasonalResponse>> => {
    return getJSON(`${getSearchAPIEndpoint()}/seasonal.php`, params);
  };
};

export type SeasonalResponseGetter = ReturnType<typeof createSeasonalResponseGetter>;
