import React, { useEffect, useRef } from 'react';

import { triggerApstag } from '../../../../../sharedModules/apstag/integration';
import { ClickType } from '../../../../../sharedModules/getClickType';
import type { Event } from '../../../../../types/Analytics';
import { FreyrEventPrefix } from '../../../../../types/FreyrEventPrefix';

import { getActions } from './getActions';
import type { HawkWidgetAdvancedProps } from './model';
import { getInitialState, reducer } from './reducer';

export const HawkWidgetAdvanced: React.FC<HawkWidgetAdvancedProps> = (props) => {
  const {
    data: defaultData,
    defaultTab,
    tabConfigs,
    switcherItems,
    selectedSwitcherItem: initialSelectedSwitcherItem,
    models: initialModels,
    params,
    getSortOptions,
    getDealData,
    dealData,
    translate,
    multiselectModels,
    sendAnalytics,
    setDealData,
    genericSharedComponents: { HawkWidgetProvider },
    site,
    getWidgetResponse,
    postForWidgetResponse,
    getAiredaleFeeds,
    getModelSuggestions,
  } = props;

  const [state, dispatch] = React.useReducer(
    reducer,
    getInitialState({
      selectedSwitcherItem: initialSelectedSwitcherItem,
      switcherItems,
      activeTab: defaultTab,
      tabConfigs,
      params,
      getSortOptions,
      multiselectModels,
      defaultData,
      defaultModels: initialModels,
      dealData,
      getDealData,
    }),
  );

  const actions = getActions(dispatch);

  const activeTabRef = useRef(state.activeTab);

  const paramsRef = useRef(state.currentParams);
  const pageRef = useRef(state.currentPage);

  // Update data whenever the currentParams are changed
  useEffect(() => {
    const tabChanged =
      activeTabRef.current && activeTabRef.current.value !== state.activeTab?.value;
    const modelChanged = paramsRef?.current?.model_name !== state.currentParams?.model_name;
    const pageChanged = pageRef.current !== state.currentPage;

    // Ensure we update the model name if the tab was changed
    // We need to show the correct model data when we change the tabs after deselecting
    // and then selecting a model with different product types
    if (state.paramsChanged && state?.currentParams?.postcode !== '') {
      state.paramsChanged = false;
      state.sendPostRequest = true;
    }
    if (state.paramsChanged) {
      // Update the data if the params were changed and the tab weren't reset
      // or the current tab isn't the 'empty' tab
      const processEffect = async (): Promise<void> => {
        // No progressive filtering if the model/tab was changed or the filters were cleared
        const progressiveFiltering =
          !modelChanged && !tabChanged && state.gaData.clickType !== 'Clear filters';

        // Append deals if the offset is greater than 0 and the page wasn't changed
        const appendDeals = pageChanged ? false : (state.currentParams.offset || 0) > 0;
        const response = await actions.getData(
          getWidgetResponse,
          getAiredaleFeeds,
          {
            ...state.currentParams,
            ...(progressiveFiltering ? { progressive_filtering: 1 } : {}),
          },
          appendDeals,
          state,
          site,
        );

        if (tabChanged) {
          setDealData(await getDealData(response));
          sendAnalytics({
            label: state.activeTab?.label,
            clickType: ClickType.TAB_CHANGED,
            prefix: FreyrEventPrefix.CLICK_FROM,
          } as Event); // TODO: remove retype into Event after fie-analytics is checked
        } else if (Object.keys(state.gaData).length > 0) {
          // Ensure we don't send a load more GA event when we preload more in the peacock widget
          setDealData(await getDealData(response));
          sendAnalytics({
            ...state.gaData,
            prefix: FreyrEventPrefix.CLICK_FROM,
          } as Event); // TODO: remove retype into Event after fie-analytics is checked
        }

        triggerApstag();
      };
      processEffect();
    }

    if (state.sendPostRequest) {
      const processEffect = async (): Promise<void> => {
        const progressiveFiltering = !modelChanged && !tabChanged;

        const appendDeals = pageChanged ? false : (state.currentParams.offset || 0) > 0;
        const response = await actions.postData(
          postForWidgetResponse,
          getAiredaleFeeds,
          {
            ...state.currentParams,
            ...(progressiveFiltering ? { progressive_filtering: 1 } : {}),
          },
          appendDeals,
          state,
          site,
        );

        if (tabChanged) {
          setDealData(await getDealData(response));
          sendAnalytics({
            label: state.activeTab?.label,
            clickType: ClickType.TAB_CHANGED,
            prefix: FreyrEventPrefix.CLICK_FROM,
          } as Event); // TODO: remove retype into Event after fie-analytics is checked
        } else if (Object.keys(state.gaData).length > 0) {
          // Ensure we don't send a load more GA event when we preload more in the peacock widget
          setDealData(await getDealData(response));
          sendAnalytics({
            ...state.gaData,
            prefix: FreyrEventPrefix.CLICK_FROM,
          } as Event); // TODO: remove retype into Event after fie-analytics is checked
        }

        triggerApstag();
      };
      processEffect();
    }

    // Update the references so we don't consider the params or activeTab
    // to have changed when they haven't
    paramsRef.current = state.currentParams;
    activeTabRef.current = state.activeTab;
    pageRef.current = state.currentPage;
  }, [
    actions.getData,
    state.currentParams,
    state.gaData,
    state.activeTab,
    state.paramsChanged,
    state.postcodeChanged,
    state.sendPostRequest,
    getDealData,
    sendAnalytics,
    state.currentPage,
    actions,
    setDealData,
  ]);

  const memoizedProps = React.useMemo(() => {
    const models = Array.from(
      new Set(
        (state.dealData.deals || []).reduce((models, deal) => {
          if (typeof deal?.model !== 'undefined') {
            models.push(deal.model);
            return models;
          }
          return models;
        }, [] as string[]),
      ),
    );

    const data = {
      ...state.data[state.activeTab?.value],
      filters: (state.data[state.activeTab?.value]?.filters || []).concat([
        {
          filter_key: 'model_name',
          category: 'model',
          values: [],
          title:
            state.activeTab?.category === 'contracts'
              ? translate('modelFilterPhoneLabel')
              : translate('shopWindowCategoryFilter'),
        },
      ]),
    };

    return {
      ...props,
      data,
      dealData: state.dealData,
      tabs: tabConfigs,
      multiModel: models.length > 1,
      activeTab: state.activeTab,
      params: state.currentParams,
      selectedFilters: state.currentSelectedFilters,
      loading: state.dataLoading,
      selectedSwitcherItem: state.selectedSwitcherItem,
      currentPage: state.currentPage,
      objectOfModels: state.objectOfModels,
      sortOptions: state.sortOptions,
      changePage: actions.changePage,
      loadMoreDeals: actions.loadMoreDeals,
      showFewerDeals: actions.showFewerDeals,
      changeTab: actions.changeTab,
      changeFilter: actions.changeFilter,
      changeRangeFilter: actions.changeRangeFilter,
      updateInputValue: actions.updateInputValue,
      updateInputPostcode: actions.updateInputPostcode,
      submitInput: actions.submitInput,
      submitPostcode: actions.submitPostcode,
      changeSort: actions.changeSort,
      clearFilters: actions.clearFilters,
      toggleCheckbox: actions.toggleCheckbox,
      changeCheckboxGroup: actions.changeCheckboxGroup,
      changeRadioButtonGroup: actions.changeRadioButtonGroup,
      getModelSuggestions,
      updateModelSuggestionsCallback: actions.updateModelSuggestions,
      scrollLeft: actions.scrollLeft,
      scrollRight: actions.scrollRight,
      loadParentModel: actions.loadParentModel,
      sortSimilarAtLastPosition: actions.sortSimilarAtLastPosition,
    };
  }, [
    props,
    tabConfigs,
    actions,
    state.data,
    state.activeTab,
    state.currentParams,
    state.currentSelectedFilters,
    state.dataLoading,
    state.selectedSwitcherItem,
    state.currentPage,
    state.objectOfModels,
    state.sortOptions,
    state.dealData,
    translate,
  ]);

  return <HawkWidgetProvider {...memoizedProps} />;
};
