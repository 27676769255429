import React, { useRef } from 'react';

import { useHawkWidgetContext } from '../HawkWidget/HawkWidgetProviderContext';

import { composeImage } from './composeImage';

export interface LazyImageProps {
  src: string;
  srcSet?: string;
  alt?: string;
  width?: number;
  height?: number;
  className?: string;
  source?: JSX.Element;
  onClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  attributes?: Record<string, string>;
}

const LazyImage: React.FC<LazyImageProps> = ({
  src,
  srcSet,
  alt,
  width,
  height,
  className,
  source,
  onClick,
  attributes,
}) => {
  const { serversideImages } = useHawkWidgetContext();
  const container: React.MutableRefObject<HTMLImageElement | null> = useRef(null);
  const inputContainer: React.MutableRefObject<HTMLInputElement | null> = useRef(null);

  // Don't setup an element if there is no src - we don't need the placeholder in this case
  // (e.g. view similar product images)
  if (!src) {
    return null;
  }

  const isServerside = typeof window === 'undefined';

  const isImgLazyLoadSupported =
    typeof HTMLImageElement !== 'undefined' && 'loading' in HTMLImageElement.prototype;

  const img = composeImage({
    src,
    srcSet,
    alt,
    width,
    height,
    className,
    source,
    onClick,
    attributes,
    serversideImages,
    container,
    inputContainer,
    element: null,
    isServerside,
    isImgLazyLoadSupported,
  });

  return img;
};

export default LazyImage;
