import type { HTMLAttributeReferrerPolicy } from 'react';

import type { apstagAttributes } from '../../../../sharedModules/apstag/getAttributes';
import type { Deal } from '../../../types/Deal';
import type { MerchantLinkData } from '../../../types/MerchantLinkData';

export interface TrackedLinkAttributesProp {
  href?: string;
  ref?: React.MutableRefObject<HTMLAnchorElement | null>;
  rel?: string;
  title?: string;
  referrerPolicy?: HTMLAttributeReferrerPolicy;
  className?: string;
  'data-product-key'?: Deal['product_key'];
  'data-url'?: Deal['offer']['link'];
  'data-model-id'?: Deal['model_id'];
  'data-match-id'?: Deal['id'];
  'data-product-type'?: Deal['product_type'];
  [apstagAttributes.asin]?: string | null;
  [apstagAttributes.tag]?: string | null;
  [apstagAttributes.ascsubtag]?: string | null;
  'data-link-merchant'?: string | null;
  'data-merchant-id'?: MerchantLinkData['id'];
  'data-merchant-name'?: MerchantLinkData['name'];
  'data-merchant-url'?: MerchantLinkData['url'];
  'data-merchant-network'?: MerchantLinkData['network'];
  'aria-label'?: string;
  'data-rawLink'?: string;
  'data-test'?: string; // used for unit testing
}

export interface TrackedLinkProps {
  trackLinkClick?: (event: React.SyntheticEvent<HTMLAnchorElement>) => void;
  customTarget?: string;
  attributes: TrackedLinkAttributesProp;
  children?: React.ReactNode;
}

export enum TargetEnum {
  SELF = '_self',
  BLANK = '_blank',
  PARENT = '_parent',
  TOP = '_top',
}
