enum FeatureValue {
  AVAILABLE_FROM = 'available_from',
  CONTRACTS_ONLY = 'contracts_only',
  DEALS_ONLY = 'deals_only',
  DEAL_WIDGET_COLLAPSIBLE_TEXT = 'deal_widget_collapsible_text',
  DISPLAY_TYPE = 'display_type',
  WIDGET_INTRODUCTION = 'widget_introduction',
  SHOW_FALLBACK = 'show_fallback',
  LINK = 'link',
  LINK_TEXT = 'link_text',
  IMAGE = 'image',
  WEBP_IMAGE = 'webp_image',
  NAME = 'name',
  SKIP = 'skip',
  ORIGIN = 'origin',
  STAR = 'star',
  STAR_LABEL = 'star_label',
  IS_PREFERRED_PARTNER = 'is_preferred_partner',
  SHOW_DEAL_WIDGET_FOOTER = 'show_deal_widget_footer',
  EXCLUDE_FROM = 'exclude_from',
  WIDGET_TYPE = 'widget_type',
  SHOW_LINK = 'show_link',
  HIDDEN_DEAL_WIDGET = 'hidden_deal_widget',
  SHOW_TOGGLE = 'show_toggle',
  SWITCH_MERCHANTLINK_TO_REVIEW = 'switch_merchantlink_to_review',
  SHOW_FILTERS = 'show_filters',
  SHOW_ACTIVE_FILTERS = 'show_active_filters',
  BLACKLISTED_FILTERS = 'blacklisted_filters',
  SHOW_FILTER_COUNTS = 'show_filter_counts',
  GAME_PLATFORM_TABS = 'game_platform_tabs',
  TABS = 'tabs',
  SHOW_BUNDLE_OFFERS = 'show_bundle_offers',
  SEASONAL = 'seasonal',
  SHOW_PRICE = 'show_price',
  SHOW_WAS_PRICE = 'show_was_price',
  SHOW_PRICE_ROUNDED = 'show_price_rounded',
  SHOW_REVIEWS = 'show_reviews',
  SHOW_REVIEWS_POSITION = 'show_reviews_position',
  WIDGET_TITLE = 'widget_title',
  SHOW_WIDGET_TITLE = 'show_widget_title',
  LOAD_MORE = 'load_more',
  SHOW_SHIPPING = 'show_shipping',
  SHOW_SHIPPING_POSITION = 'show_shipping_position',
  SHOW_PROMOS = 'show_promos',
  PROMOS_POSITION = 'promos_position',
  CUSTOM_PROMO = 'custom_promo',
  CUSTOM_PROMOS = 'custom_promos',
  COUNTDOWN_POSITION = 'countdown_position',
  DISPLAY_BUNDLEROW = 'display_bundlerow',
  DISPLAY_NAME_TYPE = 'display_name_type',
  DISPLAY_NAME_EXPANDABLE = 'display_name_expandable',
  DISPLAY_NAME_MAX = 'display_name_max',
  SERVERSIDE_IMAGES = 'serverside_images',
  MODEL_OBJECTS = 'model_objects',
  NAVIGATION = 'navigation',
  RELATED_PAGES = 'related_pages',
  PARENT_PAGE = 'parent_page',
  BREADCRUMBS = 'breadcrumbs',
  BUTTON_TYPE = 'button_type',
  ALL_DEALS_LINK = 'all_deals_link',
  ALL_DEALS_LINK_TEXT = 'all_deals_link_text',
  VISIBLE_MATCH_ID = 'visible_match_id',
  SEEN_MATCH_ID = 'seen_match_id',
  PAYWALL_ALLOWED = 'paywall_allowed',
  PRESERVE_EDITORIAL_LINK = 'preserve_editorial_link',
  USE_HAWK_PRODUCT_IMAGES = 'use_hawk_product_images',
  AVAILABLE_FROM_CLICKABLE = 'available_from_clickable',
  EDITORIAL_WAS_PRICE = 'editorial_was_price',
  EDITORIAL_PRICE = 'editorial_price',
  EDITORIAL_CURRENCY = 'editorial_currency',
  MERCHANTLINK_TEXT_TYPE = 'merchantlink_text_type',
  MODEL_BRAND = 'model_brand',
  MODEL_PRODUCT_NAME = 'model_product_name',
  PERCENTAGE_OFF_THRESHOLD = 'percentage_off_threshold',
  MERCHANTLINK_STYLE = 'merchantlink_style',
  CLICK_COUNT_TIME_SPAN = 'click_count_time_span',
  HAWK_JSON = 'hawk_json',
  IMAGE_CAPTION_TEXT = 'image_caption_text',

  // APIFeature values
  MODEL_NAME = 'model_name',
  TENANCY = 'tenancy',
  ARTICLE_TYPE = 'article_type',
  ARTICLE_CATEGORY = 'article_category',
  LANGUAGE = 'language',
  SITE = 'site',
  AREA = 'area',
  NOW = 'now',
  ALLOW_OUT_OF_STOCK = 'allow_out_of_stock',
  COMBINE_PRODUCT_TYPES = 'combine_product_types',
  CACHE_BUST = 'cache_bust',
  MODEL_ID = 'model_id',
  MATCH_ID = 'match_id',
  CUSTOM_TRACKING_ID = 'custom_tracking_id',
  FILTER_MERCHANTS = 'filter_merchants',
  FILTER_MERCHANT_NAME = 'filter_merchant_name',
  CURRENT_MINUTES = 'current_minutes',
  BATTLE = 'battle',
  FILTER_DATA = 'filter_data',
  ALL_FILTERS = 'all_filters',
  EXCLUDE_UNLABELLED = 'exclude_unlabelled',
  FILTER_TEXTS = 'filter_texts',
  FILTER_MONTHLY_COST = 'filter_monthly_cost',
  FILTER_UPFRONT = 'filter_upfront',
  FILTER_MINUTES = 'filter_minutes',
  FILTER_CONTRACT_LENGTH = 'filter_contract_length',
  FILTER_CATEGORY = 'filter_category',
  FILTER_UPGRADE = 'filter_upgrade',
  FILTER_WAS_PRICE = 'filter_was_price',
  FILTER_POSTCODE = 'filter_postcode',
  FILTER_INCLUDES = 'filter_includes',
  FILTER_SPEED = 'filter_speed',
  FILTER_TYPE = 'filter_type',
  SORT = 'sort',
  DEAL_START_TIME_FROM = 'deal_start_time_from',
  DEAL_START_TIME_TO = 'deal_start_time_to',
  DEAL_END_TIME_FROM = 'deal_end_time_from',
  DEAL_END_TIME_TO = 'deal_end_time_to',
  RAND = 'rand',
  DESC = 'desc',
  OFFSET = 'offset',
  PRESERVE_ALL_MATCHES = 'preserve_all_matches',
  DEALS_PER_MODEL = 'deals_per_model',
  DISTINCT_MERCHANTS = 'distinct_merchants',
  DISTINCT_NETWORKS = 'distinct_networks',
  DISTINCT_LABEL = 'distinct_label',
  MULTI = 'multi',
  MULTI_SIMILAR = 'multi_similar',
  KEEP_DUPLICITIES = 'keep_duplicities',
  DEDUPLICATION_STRATEGY = 'deduplication_strategy',
  OFFER_EXCL = 'offer_excl',
  OFFER = 'offer',
  RELATED_MODELS = 'related_models',
  FILTER_PRODUCT_TYPES = 'filter_product_types',
  ROWS = 'rows',
  DEVICE = 'device',
  SHOW_NO_DEALS = 'show_no_deals',
  SHOW_PRIMARY_LABEL = 'show_primary_label',
  INCLUDE_SPECS = 'include_specs',
  PRODUCT_ID = 'product_id',
  ONLY_FALLBACK_OFFERS = 'only_fallback_offers',
  VERIFIED = 'verified',
  TRANSLATE_PERCENTAGE_SAVING = 'translate_percentage_saving',
  BLOCK_LAYOUT = 'block_layout',
  BLOCK_TYPE = 'block_type',
  USE_NUCLEUS_REWRITE = 'use_nucleus_rewrite',
  EDITORIAL_MERCHANT_NAME = 'editorial_merchant_name',
  USE_DEAL_MERCHANT_LINK_TEXT = 'use_deal_merchant_link_text',
  // ORIGIN = 'origin', //Already part of WidgetFeatures
}

export default FeatureValue;
