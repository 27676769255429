import React from 'react';

import { AiredaleFeedsGetter } from '../../../../sharedModules/api/airedale/createAiredaleFeedsGetter';
import { ModelSuggestionsGetter } from '../../../../sharedModules/api/searchApi/createModelSuggestionsGetter';
import { SeasonalResponseGetter } from '../../../../sharedModules/api/searchApi/createSeasonalResponseGetter';
import { WidgetResponseGetter } from '../../../../sharedModules/api/searchApi/createWidgetResponseGetter';
import { WidgetResponsePost } from '../../../../sharedModules/api/searchApi/createWidgetResponsePost';
import type { IAnalyticsMethods } from '../../../hooks/useAnalytics';
import type { SiteWithPlatform } from '../../../modules/sites/types/Site';
import type { APIData } from '../../../types/APIData';
import type { Deal, Promo } from '../../../types/Deal';
import type { DealData } from '../../../types/DealData';
import type { GenericSharedComponents } from '../../../types/GenericSharedComponents';
import type { GetDealData } from '../../../types/GetDealData';
import type { Translate } from '../../../types/Localiser';
import { MerchantLinkData } from '../../../types/MerchantLinkData';
import type { ReviewData } from '../../../types/ModelInfo';
import type { Tab } from '../../../types/Tab';
import type { Widget } from '../../../types/Widget';
import { WidgetProps } from '../../../types/WidgetProps';
import type { SortOption } from '../../Shop/model';
import type { SpecsItem } from '../../Specs/model';
import { ClickCountTimeSpan } from '../../Vulture/getUsageString';

import type { Actions, Filters, SortOptions, TabConfig } from './HawkWidgetAdvanced/model';

export interface HawkWidgetProviderInitialValue {
  dealData?: DealData;
}

export type VoucherCode = Promo | null;

export enum MerchantlinkStyle {
  BOX = 'box',
  HYPERLINK = 'hyperlink',
  MODERN_BUTTON = 'modernButton',
}

export enum VultureApiCallStatus {
  SUCCESS = 'success',
  FAILURE = 'failure',
  EMPTY = '',
}

export interface HawkWidgetProviderContext {
  // TODO(Petr): HAWK-7003 API calls that must be passed to HawkWidgetProvider,
  //  therefore to HawkWidgetStandard, therefore to MasterWidgetMain,
  //  therefore returned from getInitialiserProps,
  //  therefore passed to getInitialiserProps, which calls getWidgetInitialiser,
  //  therefore passed and returned from getWidgetInitialiser,
  //  therefore they need to be in the WidgetProps type.
  getWidgetResponse: WidgetResponseGetter;
  getSeasonalResponse: SeasonalResponseGetter;
  getAiredaleFeeds: AiredaleFeedsGetter;
  getModelSuggestions: ModelSuggestionsGetter;
  postForWidgetResponse: WidgetResponsePost;

  allDealsVisible: boolean;
  getDealData: GetDealData;
  getSortOptions: (activeTab: TabConfig) => SortOption[];
  seasonalResponse: WidgetProps['seasonalResponse'];
  multiselectModels: boolean | null;
  setDealData: (dealData: DealData) => void;
  title: string;
  availableFrom: boolean;
  showWidgetTitle: boolean;
  onTouchEnd: (event: React.SyntheticEvent<HTMLElement>) => void;
  onTouchStart: (event: React.SyntheticEvent<HTMLElement>) => void;
  showShipping: boolean;
  multiModel: boolean;
  activeTab: Tab;
  allDealsLink: string;
  allDealsLinkText: string;
  // TODO(Petr): HAWK-6881 Remove
  vultureApiCallStatus: VultureApiCallStatus;
  setVultureApiCallStatus: (status: string) => void;
  area: string;
  articleId: string;
  articleName: string;
  articleUrl: string;
  availableFromClickable: boolean;
  blacklistedFilters: string;
  breadcrumbs: string;
  buttonType: string;
  changeCheckboxGroup: Actions['changeCheckboxGroup'];
  changeFilter: Actions['changeFilter'];
  changePage: Actions['changePage'];
  changeRadioButtonGroup: Actions['changeRadioButtonGroup'];
  changeRangeFilter: Actions['changeRangeFilter'];
  changeSort: Actions['changeSort'];
  changeTab: Actions['changeTab'];
  clearFilters: Actions['clearFilters'];
  clickCountTimeSpan: ClickCountTimeSpan;
  clickLink: (event: React.SyntheticEvent<HTMLAnchorElement>) => void;
  countdownPosition: string;
  currentPage: number;
  data: APIData;
  dealData: DealData;
  defaultTab: Tab | null;
  displayBundleRow: boolean;
  displayNameExpandable: boolean;
  displayNameMax: number;
  displayNameType: string;
  editorial: boolean;
  email: string;
  enterEmail: (e: React.SyntheticEvent) => void;
  error: string;
  excludeFrom: string[];
  expiredDealTime: boolean;
  filtersVisible: boolean | null;
  genericSharedComponents: GenericSharedComponents;
  image: string;
  inFocus: boolean;
  items: SpecsItem[];
  revealedPopupMatchId?: number;
  language: string;
  link: string;
  linkText: string;
  loadMore: boolean;
  loadMoreDeals: Actions['loadMoreDeals'];
  loadParentModel: Actions['loadParentModel'];
  loading: boolean;
  merchantlinkTextType: string;
  merchantlinkStyle: MerchantlinkStyle;
  modelBrand: string;
  modelName: string;
  modelObjects: string;
  models: Record<string, number> | string[];
  mostPopularWidget: boolean;
  modelProductName: string;
  name: string;
  navigation: string;
  objectOfModels: Record<string, number>[];
  origin: string;
  params: Record<string, any>;
  parentPage: string;
  platform: string;
  postcodeSubmitted: boolean;
  productName: string;
  promosPosition: string;
  relatedPages: string;
  reviews: ReviewData[];
  scrollLeft: Actions['scrollLeft'];
  scrollRight: Actions['scrollRight'];
  seasonal: string;
  seenMatchId: string;
  selectedFilters: Filters;
  selectedModels: string[];
  selectedSwitcherItem: string;
  sendAnalytics: IAnalyticsMethods['sendAnalytics'];
  sendData: (e: React.FormEvent) => Promise<void>;
  serversideImages: boolean;
  setLoading: (value: boolean) => boolean;
  setPopupDeal: (deal: Deal | null) => void;
  showActiveFilters: boolean;
  showFallback: string;
  showFewerDeals: Actions['showFewerDeals'];
  showFilterCounts: boolean;
  showFilters: boolean;
  showLink: boolean;
  showPrice: string;
  showPriceRounded: string;
  showPrimaryLabel: boolean;
  showPromos: string;
  showReviewsPosition: string;
  showShippingPosition: string;
  showWasPrice: string;
  site: SiteWithPlatform;
  siteName: string;
  sortOptions: SortOptions[];
  sortSimilarAtLastPosition: Actions['sortSimilarAtLastPosition'];
  star: string;
  submitInput: Actions['submitInput'];
  submitPostcode: Actions['submitPostcode'];
  switchMerchantlinkToReview: boolean;
  switcherItems: string[];
  tabConfigs: Tab[];
  tabs: Tab[];
  toggleCheckbox: Actions['toggleCheckbox'];
  toggleFilters: () => void;
  togglePostcodeSubmittedStatus: () => void;
  translate: Translate;
  uniqueCodeData: string;
  updateInputValue: Actions['updateInputValue'];
  updateInputPostcode: Actions['updateInputPostcode'];
  updateModelSuggestionsCallback: Actions['updateModelSuggestions'];
  url: string;
  useHawkProductImages: boolean;
  webpImage: string;
  widget: Widget;
  widgetIntroduction: string;
  widgetTitle: string;
  children?: React.ReactNode;
  percentageOffThreshold: number;
  dataLinkMerchant?: MerchantLinkData;
  editorialMerchantName?: string;
}
