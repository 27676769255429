import { GetJSON } from '../apiUtils';

export type AiredaleCategory = {
  articlevoucherretailercategory: string;
  canonical: string;
};

export type AiredaleResponse = AiredaleCategory[];

type AiredaleFeedsParams = {
  site?: string;
  length?: number;
  retailerCategory: string;
  territory?: string;
  fields?: string;
};

export const createAiredaleFeedsGetter = (getJSON: GetJSON<AiredaleResponse>) => {
  return async (params: AiredaleFeedsParams): Promise<AiredaleResponse> => {
    const {
      site,
      length = 100,
      retailerCategory,
      territory,
      fields = 'articleVoucherRetailerCategory,canonical',
    } = params;

    const airedaleEndpoint = process.env.AIREDALE_ENDPOINT;
    if (!airedaleEndpoint) {
      throw new Error('AIREDALE_ENDPOINT environment variable is not defined');
    }

    const response = await getJSON(airedaleEndpoint, {
      site,
      nb_results: length,
      articleVoucherRetailerCategory: retailerCategory,
      fl: fields,
      articleTerritory: territory,
    });

    if (!response.ok) {
      throw new Error("Couldn't fetch Airedale feeds");
    }
    return response.data;
  };
};

export type AiredaleFeedsGetter = ReturnType<typeof createAiredaleFeedsGetter>;
