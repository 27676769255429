import { Logger } from 'pino';

import { encodeQueryString, QueryParams } from '../../utils/encodeQueryString';
import ServerMetrics from '../../wss/utils/ServerMetrics';

type FailedApiResponse = {
  ok: false;
  status: number;
  detail: string;
};

type SuccessApiResponse<T> = {
  ok: true;
  status: number;
  headers: Headers;
  data: T;
};

export type ApiResponse<T> = FailedApiResponse | SuccessApiResponse<T>;

export const getText = async (url: string): Promise<ApiResponse<string>> => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      return {
        ok: false,
        status: response.status,
        detail: 'Unknown error while fetching data',
      };
    }
    const data = await response.text();
    return {
      ok: true,
      status: response.status,
      headers: response.headers,
      data,
    };
  } catch (e) {
    if (e instanceof DOMException && e.name === 'AbortError') {
      return {
        ok: false,
        status: 0,
        detail: 'Fetch aborted by user action',
      };
    }
    return {
      ok: false,
      status: 500,
      detail: 'Unknown error while fetching data',
    };
  }
};

export const getJSON = async <T>(url: string, params?: QueryParams): Promise<ApiResponse<T>> => {
  // Disable request timeout on client
  const isClient = typeof window !== 'undefined';
  const maxTime = isClient ? NaN : parseInt(process.env.API_REQUEST_TIMEOUT ?? '', 10);

  try {
    const response = await fetch(`${url}${encodeQueryString(params)}`, {
      signal: isNaN(maxTime) ? undefined : AbortSignal.timeout(maxTime),
    });
    if (!response.ok) {
      return {
        ok: false,
        status: response.status,
        detail: 'Unknown error while fetching data',
      };
    }
    const data = (await response.json()) as T;
    return {
      ok: true,
      status: response.status,
      headers: response.headers,
      data,
    };
  } catch (e) {
    if (e instanceof DOMException && e.name === 'AbortError') {
      return {
        ok: false,
        status: 0,
        detail: 'Fetch aborted by user action',
      };
    }
    if (e instanceof DOMException && e.name === 'TimeoutError') {
      return {
        ok: false,
        status: 408,
        detail: 'Request timed out',
      };
    }
    return {
      ok: false,
      status: 500,
      detail: 'Unknown error while fetching data',
    };
  }
};

export type GetJSON<T> = (url: string, params?: QueryParams) => Promise<ApiResponse<T>>;

export const createGetJSONWithMetrics =
  <T>(metrics: ServerMetrics, logger: Logger, type: string, wssEndpoint: string) =>
  async (url: string, params?: QueryParams): Promise<ApiResponse<T>> => {
    const startTime = Date.now();
    const response = await getJSON<T>(url, params);
    const duration = Date.now() - startTime;

    if (response.ok) {
      metrics.storeRequestTime(type, 'success', wssEndpoint, duration);
    } else {
      logger.error(
        {
          type,
          wssEndpoint,
          url,
          params,
          status: response.status,
          detail: response.detail,
          duration,
        },
        'API request failed',
      );
      const result = response.status === 408 ? 'timeout' : 'error';
      metrics.storeRequestTime(type, result, wssEndpoint, duration);
    }

    // TODO(Petr): HAWK-7001 Remove
    if (response.ok) {
      const cacheStatus = response.headers.get('x-ftr-cache-status');
      if (cacheStatus) {
        // TODO(Petr): HAWK-7001 url might not be 'widget.php',
        //  but storing varnish cache status will be removed so it's fine for now
        metrics.storeVarnishCacheStatus('widget.php', cacheStatus);
      }
    }

    return response;
  };

export const createGetJSONWithOverride = <T>(
  overrideData: T | undefined,
  getJSON: GetJSON<T>,
): GetJSON<T> => {
  return async (url: string, params?: QueryParams) => {
    if (overrideData) {
      return {
        ok: true,
        status: 200,
        headers: new Headers(),
        data: overrideData,
      };
    }
    return getJSON(url, params);
  };
};

// TODO(Petr): HAWK-7013 Create createPostJSONWithMetrics?
export const postJSON = async <T>(url: string, data: unknown): Promise<ApiResponse<T>> => {
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      return {
        ok: false,
        status: response.status,
        detail: 'Unknown error while fetching data',
      };
    }
    const responseData = (await response.json()) as T;
    return {
      ok: true,
      status: response.status,
      headers: response.headers,
      data: responseData,
    };
  } catch (e) {
    return {
      ok: false,
      status: 500,
      detail: 'Unknown error while fetching data',
    };
  }
};

// TODO(Petr): HAWK-7013 Merge with the other post
export const postForm = async <T>(
  url: string,
  formData: FormData,
  params?: QueryParams,
): Promise<ApiResponse<T>> => {
  try {
    const response = await fetch(`${url}${encodeQueryString(params)}`, {
      method: 'POST',
      body: formData,
    });
    if (!response.ok) {
      return {
        ok: false,
        status: response.status,
        detail: 'Unknown error while fetching data',
      };
    }
    const responseData = (await response.json()) as T;
    return {
      ok: true,
      status: response.status,
      headers: response.headers,
      data: responseData,
    };
  } catch (e) {
    return {
      ok: false,
      status: 500,
      detail: 'Unknown error while fetching data',
    };
  }
};

export type PostForm<T> = (
  url: string,
  formData: FormData,
  params?: QueryParams,
) => Promise<ApiResponse<T>>;
