import { Site } from '../core/modules/sites/types/Site';

import { getSiteEndpoint } from './getSiteEndpoint';

/**
 * Get the widgets assets endpoint (e.g. CDN url or local URL)
 */
export const getWidgetsAssetsEndpoint = (site?: Site): string | undefined => {
  // Support a custom endpoint - necessary if FTE loads hawk from a different domain to the default
  if (typeof window !== 'undefined' && window.hawk_widgets_endpoint) {
    return window.hawk_widgets_endpoint;
  }

  if (site) {
    const siteEndpoint = getSiteEndpoint(site);
    if (siteEndpoint) {
      return siteEndpoint;
    }
  }

  return process.env.ASSETS_ENDPOINT;
};
