export enum EmailVerificationStates {
  UNSENT,
  LOADING,
  SUCCESS,
  FAILURE,
}

export type UserState = {
  user: null | { id: string | null; email: string | null; isVerified: boolean };
  sessionId: string;
  isSessionLoaded: boolean;
  emailVerificationState: EmailVerificationStates;
  signupForm: {
    error: string;
    isLoading: boolean;
    email: string;
    terms: boolean;
  };
};

export type Audience = {
  regex: RegExp;
  noun: string;
};

export type Actions = {
  getSession: () => void;
  inputUpdate: (update: { email: string } | { terms: boolean }) => void;
  sendVerificationEmail: (siteName: string, email?: string) => Promise<void>;
  signupUser: (
    siteName: string,
    terms: boolean,
    email?: string,
    audience?: Audience,
  ) => Promise<void>;
};

export enum ActionTypes {
  GET_SESSION = 'GET_SESSION',
  SIGNUP_INIT = 'SIGNUP_INIT',
  SET_EMAIL_SENDING = 'SET_EMAIL_SENDING',
  FORM_ERROR = 'FORM_ERROR',
  FORM_INPUT_CHANGE = 'FORM_INPUT_CHANGE',
}

export type UserStateAction =
  | { type: ActionTypes.GET_SESSION; payload: string }
  | { type: ActionTypes.SIGNUP_INIT }
  | { type: ActionTypes.SET_EMAIL_SENDING; payload: EmailVerificationStates }
  | { type: ActionTypes.FORM_ERROR; payload: { error: string } }
  | { type: ActionTypes.FORM_INPUT_CHANGE; payload: { email: string } | { terms: boolean } };
