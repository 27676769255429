/**
 * return hawk_force_url_path or url pathname
 */
export default (): string => {
  // Allow us to force a url (for dev purposes)
  if (typeof window !== 'undefined' && typeof window.hawk_force_url_path !== 'undefined') {
    // TODO this should be handled in a different way
    return window.hawk_force_url_path;
  }
  return typeof window !== 'undefined' ? window.location.pathname : '';
};
