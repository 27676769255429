import React, { useEffect, useState } from 'react';

import {
  apstagAttributes,
  supportedMerchant,
} from '../../../../sharedModules/apstag/getAttributes';
import { addAsinToBidStore } from '../../../../sharedModules/apstag/integration';
import { useHawkWidgetContext } from '../HawkWidget/HawkWidgetProviderContext';

import { TargetEnum } from './model';
import type { TrackedLinkProps } from './model';
import { addExternalLinkRelations, addNcaAttributes, getTarget } from './modules/attribute';

const TrackedLink: React.FC<TrackedLinkProps> = (props) => {
  const middleClick = (event: React.MouseEvent<HTMLAnchorElement>): void => {
    const { trackLinkClick } = props;
    event.stopPropagation();
    // Track the click if it is a middle click
    // middle clicks don't fire onClick or onContextMenu events
    if (typeof event.button !== 'undefined' && event.button === 1 && trackLinkClick) {
      trackLinkClick(event);
    }
  };

  const click = (event: React.MouseEvent<HTMLAnchorElement>): void => {
    const { trackLinkClick } = props;
    event.stopPropagation();
    // Track the click if it is not a middle click
    // middle clicks don't fire onClick or onContextMenu events
    // Added to ensure Firefox doesn't fire both click events
    if (typeof event.button !== 'undefined' && event.button !== 1 && trackLinkClick) {
      trackLinkClick(event);
    }
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLAnchorElement>): void => {
    const { trackLinkClick } = props;
    event.stopPropagation();

    if (typeof event.key !== 'undefined' && event.code === 'Enter' && trackLinkClick) {
      trackLinkClick(event);
    }
  };

  const { children, customTarget, attributes: propAttributes } = props;
  const attributesState = useState(propAttributes);
  let [attributes] = attributesState;
  const [, setAttributes] = attributesState;
  const { translate, site } = useHawkWidgetContext();

  // don't add 'sponsored noopener' to internal links
  if (site?.url && attributes?.href && getTarget(site.url, attributes.href) === TargetEnum.BLANK) {
    attributes = addExternalLinkRelations(attributes);
  }

  useEffect(() => {
    // What data props are passed into this component are unknown/can change
    // as such the only consistent way to know if the link might be for amazon
    // is if the url contains the word amazon
    if (attributes.href?.includes(supportedMerchant)) {
      const updatedAttributes = addNcaAttributes(attributes);
      if (updatedAttributes[apstagAttributes.asin]) {
        addAsinToBidStore(updatedAttributes[apstagAttributes.asin]);
      }
      setAttributes(updatedAttributes);
    }
  }, [propAttributes]);

  return (
    <a
      onClick={click}
      onContextMenu={click}
      onMouseDown={middleClick}
      data-google-interstitial="false"
      aria-label={translate('defaultLinkText')}
      {...(attributes ?? {})}
      target={customTarget ?? getTarget(site?.url, attributes.href)}
      onKeyDown={onKeyDown}
      role="link"
      tabIndex={0}
    >
      {children}
    </a>
  );
};

export default TrackedLink;
