enum FeatureId {
  // WidgetFeatures ids
  AVAILABLE_FROM = 'availableFrom',
  CONTRACTS_ONLY = 'contractsOnly',
  DEALS_ONLY = 'dealsOnly',
  DEAL_WIDGET_COLLAPSIBLE_TEXT = 'dealWidgetCollapsibleText',
  DISPLAY_TYPE = 'displayType',
  WIDGET_INTRODUCTION = 'widgetIntroduction',
  SHOW_FALLBACK = 'showFallback',
  LINK = 'link',
  LINK_TEXT = 'linkText',
  IMAGE = 'image',
  WEBP_IMAGE = 'webpImage',
  NAME = 'name',
  SKIP = 'skip',
  ORIGIN = 'origin',
  STAR = 'star',
  STAR_LABEL = 'starLabel',
  IS_PREFERRED_PARTNER = 'isPreferredPartner',
  SHOW_DEAL_WIDGET_FOOTER = 'showDealWidgetFooter',
  EXCLUDE_FROM = 'excludeFrom',
  WIDGET_TYPE = 'widgetType',
  SHOW_LINK = 'showLink',
  HIDDEN_DEAL_WIDGET = 'hiddenDealWidget',
  SHOW_TOGGLE = 'showToggle',
  SWITCH_MERCHANTLINK_TO_REVIEW = 'switchMerchantlinkToReview',
  SHOW_FILTERS = 'showFilters',
  SHOW_ACTIVE_FILTERS = 'showActiveFilters',
  BLACKLISTED_FILTERS = 'blacklistedFilters',
  SHOW_FILTER_COUNTS = 'showFilterCounts',
  GAME_PLATFORM_TABS = 'gamePlatformTabs',
  TABS = 'tabs',
  SHOW_BUNDLE_OFFERS = 'showBundleOffers',
  SEASONAL = 'seasonal',
  SHOW_PRICE = 'showPrice',
  SHOW_WAS_PRICE = 'showWasPrice',
  SHOW_PRICE_ROUNDED = 'showPriceRounded',
  SHOW_REVIEWS = 'showReviews',
  SHOW_REVIEWS_POSITION = 'showReviewsPosition',
  WIDGET_TITLE = 'widgetTitle',
  SHOW_WIDGET_TITLE = 'showWidgetTitle',
  LOAD_MORE = 'loadMore',
  SHOW_SHIPPING = 'showShipping',
  SHOW_SHIPPING_POSITION = 'showShippingPosition',
  SHOW_PROMOS = 'showPromos',
  PROMOS_POSITION = 'promosPosition',
  CUSTOM_PROMO = 'customPromo',
  CUSTOM_PROMOS = 'customPromos',
  COUNTDOWN_POSITION = 'countdownPosition',
  DISPLAY_BUNDLE_ROW = 'displayBundleRow',
  DISPLAY_NAME_TYPE = 'displayNameType',
  DISPLAY_NAME_EXPANDABLE = 'displayNameExpandable',
  DISPLAY_NAME_MAX = 'displayNameMax',
  SERVERSIDE_IMAGES = 'serversideImages',
  MODEL_OBJECTS = 'modelObjects',
  NAVIGATION = 'navigation',
  RELATED_PAGES = 'relatedPages',
  PARENT_PAGE = 'parentPage',
  BREADCRUMBS = 'breadcrumbs',
  BUTTON_TYPE = 'buttonType',
  ALL_DEALS_LINK = 'allDealsLink',
  ALL_DEALS_LINK_TEXT = 'allDealsLinkText',
  VISIBLE_MATCH_ID = 'visibleMatchId',
  SEEN_MATCH_ID = 'seenMatchId',
  PAYWALL_ALLOWED = 'paywallAllowed',
  PRESERVE_EDITORIAL_LINK = 'preserveEditorialLink',
  USEHAWK_PRODUCT_IMAGES = 'useHawkProductImages',
  AVAILABLE_FROM_CLICKABLE = 'availableFromClickable',
  EDITORIAL_WAS_PRICE = 'editorialWasPrice',
  EDITORIAL_PRICE = 'editorialPrice',
  EDITORIAL_CURRENCY = 'editorialCurrency',
  MERCHANTLINK_TEXT_TYPE = 'merchantlinkTextType',
  MODEL_PRODUCT_NAME = 'modelProductName',
  PERCENTAGE_OFF_THRESHOLD = 'percentageOffThreshold',
  MERCHANTLINK_STYLE = 'merchantlinkStyle',
  CLICK_COUNT_TIME_SPAN = 'clickCountTimeSpan',
  HAWK_JSON = 'hawkJson',
  IMAGE_CAPTION_TEXT = 'imageCaptionText',

  // APIFeatures ids
  // ORIGIN = 'origin', (it was already in WidgetFeatures)
  MODEL_NAME = 'modelName',
  TENANCY = 'tenancy',
  ARTICLE_TYPE = 'articleType',
  ARTICLE_CATEGORY = 'articleCategory',
  LANGUAGE = 'language',
  SITE = 'site',
  AREA = 'area',
  NOW = 'now',
  ALLOW_OUT_OF_STOCK = 'allowOutOfStock',
  COMBINE_PRODUCT_TYPES = 'combineProductTypes',
  CACHE_BUST = 'cacheBust',
  MODEL_ID = 'modelId',
  MATCH_ID = 'matchId',
  CUSTOM_TRACKING_ID = 'customTrackingId',
  MERCHANTS = 'merchants',
  MERCHANT = 'merchant',
  CURRENT_MINUTES = 'currentMinutes',
  BATTLE = 'battle',
  DATA = 'data',
  ALL_FILTERS = 'allFilters',
  EXCLUDE_UNLABELLED = 'excludeUnlabelled',
  TEXTS = 'texts',
  MONTHLY_COST = 'monthlyCost',
  UPFRONT_COST = 'upfrontCost',
  MINUTES = 'minutes',
  CONTRACT_LENGTH = 'contractLength',
  CATEGORY = 'category',
  UPGRADES = 'upgrades',
  WASPRICE = 'wasPrice',
  POSTCODE = 'postcode',
  INCLUDES = 'includes',
  SPEED = 'speed',
  TYPE = 'type',
  SORT = 'sort',
  DEAL_START_TIME_FROM = 'dealStartTimeFrom',
  DEAL_START_TIME_TO = 'dealStartTimeTo',
  DEAL_END_TIME_FROM = 'dealEndTimeFrom',
  DEAL_END_TIME_TO = 'dealEndTimeTo',
  RAND = 'rand',
  DESC = 'desc',
  OFFSET = 'offset',
  PRESERVE_ALL_MATCHES = 'preserveAllMatches',
  DEALS_PER_MODEL = 'dealsPerModel',
  DISTINCT_MERCHANTS = 'distinctMerchants',
  DISTINCT_NETWORKS = 'distinctNetworks',
  DISTINCT_LABEL = 'distinctLabel',
  MULTI = 'multi',
  MULTI_SIMILAR = 'multiSimilar',
  KEEP_DUPLICITIES = 'keepDuplicities',
  DEDUPLICATION_STRATEGY = 'deduplicationStrategy',
  OFFER_EXCL = 'offerExcl',
  OFFER = 'offer',
  RELATED_MODELS = 'relatedModels',
  PRODUCT_TYPES = 'productTypes',
  ROWS = 'rows',
  DEVICE = 'device',
  SHOW_NO_DEALS = 'showNoDeals',
  SHOW_PRIMARY_LABEL = 'showPrimaryLabel',
  INCLUDE_SPECS = 'includeSpecs',
  PRODUCT_ID = 'productId',
  ONLY_FALLBACK_OFFERS = 'onlyFallbackOffers',
  MODEL_BRAND = 'modelBrand',
  VERIFIED = 'verified',
  TRANSLATE_PERCENTAGE_SAVING = 'translatePercentageSaving',
  BLOCK_LAYOUT = 'blockLayout',
  BLOCK_TYPE = 'blockType',
  USE_NUCLEUS_REWRITE = 'useNucleusRewrite',
  EDITORIAL_MERCHANT_NAME = 'editorialMerchantName',
  USE_DEAL_MERCHANT_LINK_TEXT = 'useDealMerchantLinkText',
}

export default FeatureId;
