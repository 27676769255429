import { getNodes } from '../assets/modules/getNodes';
import convertToArray from '../assets/utils/convertToArray';

export const hideLinkedElements = async (el: HTMLElement | null): Promise<void> => {
  const widgetLinkedElementIds = el?.getAttribute('data-hawk-fallback-id');
  const linkedElements: HTMLElement[] = [];

  for (const id of (widgetLinkedElementIds || '').split(',')) {
    const elements = getNodes({
      selector: '[data-hawk-fallback-ids]:not(.hawk-base)',
    });
    convertToArray(elements).forEach((fallbackElement: HTMLElement) => {
      const ids = fallbackElement.getAttribute('data-hawk-fallback-ids');
      if (ids && ids.split(',').find((fallbackId) => fallbackId === id)) {
        linkedElements.push(fallbackElement);
      }
    });
  }

  linkedElements.forEach((linkedElement) =>
    linkedElement.style.setProperty('display', 'none', 'important'),
  );
};
