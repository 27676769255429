import { redeemUniqueCodePerDeal } from '../../../../../../../../sharedModules/api/searchApi/redeemUniqueCodePerDeal';
import { Site } from '../../../../../../../modules/sites/types/Site';
import { Deal } from '../../../../../../../types/Deal';
import { Translate } from '../../../../../../../types/Localiser';
import { VultureApiCallStatus } from '../../../../../HawkWidget/model';

export const loadUniqueCode = async (
  setLoading: (value: boolean) => boolean,
  setVultureApiCallStatus: (status: VultureApiCallStatus) => void,
  setUniqueCodeData: (code: string) => void,
  translate: Translate,
  site: Site,
  area: string,
  popupDeal: Deal,
): Promise<void> => {
  try {
    setLoading(true);
    const uniqueCode = await redeemUniqueCodePerDeal(site, area, popupDeal);

    setLoading(false);
    setVultureApiCallStatus(VultureApiCallStatus.SUCCESS);

    return setUniqueCodeData(uniqueCode);
  } catch (e) {
    setLoading(false);
    setVultureApiCallStatus(VultureApiCallStatus.FAILURE);

    return setUniqueCodeData(translate('ranOutOfCodesMessage'));
  }
};
