import type { Site } from '../types/Site';

const sites: Site[] = [
  {
    id: '5gradar',
    name: '5gradar',
    value: '5gradar',
    logo: 'svg/5gradar.svg',
    label: '5G Radar',
    vanillaName: '5gradar',
    analyticsName: '5gradar',
    selligent_code: '5GR',
    url: '5gradar.com',
  },
  {
    id: 'advnture',
    name: 'advnture',
    value: 'advnture',
    logo: 'svg/advnture.svg',
    label: 'Advnture',
    vanillaName: 'advnture',
    analyticsName: 'advnture',
    selligent_code: 'ADV',
    url: 'advnture.com',
  },
  {
    id: 'androidcentral',
    name: 'androidcentral',
    value: 'ac',
    logo: 'svg/androidcentral.svg',
    label: 'Android Central',
    vanillaName: 'androidcentral',
    analyticsName: 'ac',
    url: 'androidcentral.com',
  },
  {
    id: 'avnetwork',
    name: 'avnetwork',
    value: 'avnetwork',
    logo: 'svg/avnetwork.svg',
    label: 'AV Network',
    vanillaName: 'avnetwork',
    analyticsName: 'avnetwork',
    selligent_code: 'AVT',
    url: 'avnetwork.com',
  },
  {
    id: 'bikeperfect',
    name: 'bikeperfect',
    value: 'bikeperfect',
    logo: 'svg/bikeperfect.svg',
    label: 'Bike Perfect',
    vanillaName: 'bikeperfect',
    analyticsName: 'bikeperfect',
    url: 'bikeperfect.com',
  },
  {
    id: 'cinemablend',
    name: 'cinemablend',
    value: 'cinemablend',
    logo: 'svg/cinemablend.svg',
    label: 'Cinemablend',
    vanillaName: 'cinemablend',
    analyticsName: 'cinemablend',
    url: 'cinemablend.com',
  },
  {
    id: 'cbq',
    name: 'creativebloq',
    value: 'cbq',
    logo: 'svg/cbq.svg',
    label: 'Creative Bloq',
    vanillaName: 'creativebloq',
    analyticsName: 'cbq',
    selligent_code: 'CBQ',
    url: 'creativebloq.com',
    endpoints: {
      stage: 'https://stage.hawk.creativebloq.com',
      live: 'https://hawk.creativebloq.com',
    },
  },
  {
    id: 'cn',
    name: 'cyclingnews',
    value: 'cyclingnews',
    logo: 'svg/cyclingnews.svg',
    label: 'Cycling News',
    vanillaName: 'cyclingnews',
    analyticsName: 'cyclingnews',
    selligent_code: 'CYL',
    url: 'cyclingnews.com',
  },
  {
    id: 'coachmag',
    name: 'coachmag',
    value: 'coachmag',
    logo: 'svg/coach.svg',
    label: 'Coach',
    alias: 'coachmag',
    vanillaName: 'coachmag',
    analyticsName: 'coachmag',
    url: 'coachweb.com',
  },
  {
    id: 'dcw',
    name: 'digitalcameraworld',
    value: 'dcw',
    logo: 'svg/digitalcameraworld.svg',
    label: 'Digital Camera World',
    vanillaName: 'digitalcameraworld',
    analyticsName: 'dcw',
    selligent_code: 'DCM',
    url: 'digitalcameraworld.com',
    endpoints: {
      stage: 'https://stage.hawk.digitalcameraworld.com',
      live: 'https://hawk.digitalcameraworld.com',
    },
  },
  {
    id: 'fourfourtwo',
    name: 'fourfourtwo',
    value: 'fourfourtwo',
    logo: 'svg/fourfourtwo.svg',
    label: 'Four Four Two',
    vanillaName: 'fourfourtwo',
    analyticsName: 'fourfourtwo',
    selligent_code: 'FFT',
    url: 'fourfourtwo.com',
  },
  {
    id: 'getprice',
    name: 'getprice',
    value: 'getprice',
    logo: 'svg/getprice.svg',
    label: 'GetPrice AU',
    vanillaName: 'getprice',
    analyticsName: 'getprice',
    url: 'getprice.com.au',
  },
  {
    id: 'grd',
    name: 'gamesradar',
    value: 'grd',
    logo: 'svg/gamesradar.svg',
    label: 'Gamesradar',
    vanillaName: 'gamesradar',
    analyticsName: 'grd',
    selligent_code: 'GAM',
    url: 'gamesradar.com',
  },
  {
    id: 'guitarplayer',
    name: 'guitarplayer',
    value: 'guitarplayer',
    logo: 'svg/guitarplayer.svg',
    label: 'Guitar World',
    vanillaName: 'guitarplayer',
    analyticsName: 'guitarplayer',
    selligent_code: 'GUP',
    url: 'guitarplayer.com',
  },
  {
    id: 'guitarworld',
    name: 'guitarworld',
    value: 'guitarworld',
    logo: 'svg/guitarworld.svg',
    label: 'Guitar World',
    vanillaName: 'guitarworld',
    analyticsName: 'guitarworld',
    selligent_code: 'GUW',
    url: 'guitarworld.com',
  },
  {
    id: 'homebuilding',
    name: 'homebuilding',
    value: 'homebuilding',
    logo: 'svg/homebuilding.svg',
    label: 'Home Building',
    analyticsName: 'homebuilding',
    vanillaName: 'homebuilding',
    selligent_code: 'HBR',
    url: 'homebuilding.co.uk',
  },
  {
    id: 'imore',
    name: 'imore',
    value: 'im',
    logo: 'svg/imore.svg',
    label: 'IMore',
    vanillaName: 'imore',
    analyticsName: 'imore',
    url: 'imore.com',
  },
  {
    id: 'itpro',
    name: 'itpro',
    value: 'itpro',
    logo: 'svg/itpro.svg',
    label: 'ITpro',
    vanillaName: 'itpro',
    analyticsName: 'itpro',
    url: 'itpro.co', // itpro.com & itpro.co.uk share this site config for just fie-widgets
  },
  {
    id: 'ltm',
    name: 'laptopmag',
    value: 'laptopmag',
    logo: 'svg/laptopmag.svg',
    label: 'Laptop Mag',
    vanillaName: 'laptopmag',
    analyticsName: 'laptopmag',
    selligent_code: 'LTP',
    url: 'laptopmag.com',
    endpoints: {
      stage: 'https://stage.hawk.laptopmag.com',
      live: 'https://hawk.laptopmag.com',
    },
  },
  {
    id: 'livescience',
    name: 'livescience',
    value: 'livescience',
    logo: 'svg/livescience.svg',
    label: 'Live Science',
    vanillaName: 'livescience',
    analyticsName: 'livescience',
    selligent_code: 'LVS',
    url: 'livescience.com',
  },
  {
    id: 'loudersound',
    name: 'loudersound',
    value: 'loudersound',
    logo: 'svg/loudersound.svg',
    label: 'Louder Sound',
    vanillaName: 'loudersound',
    analyticsName: 'loudersound',
    selligent_code: 'LOU',
    url: 'loudersound.com',
  },
  {
    id: 'mrd',
    name: 'musicradar',
    value: 'mrd',
    logo: 'svg/musicradar.svg',
    label: 'Musicradar',
    vanillaName: 'musicradar',
    analyticsName: 'mrd',
    selligent_code: 'MUR',
    url: 'musicradar.com',
    endpoints: {
      stage: 'https://stage.hawk.musicradar.com',
      live: 'https://hawk.musicradar.com',
    },
  },
  {
    id: 'nexttv',
    name: 'nexttv',
    value: 'nexttv',
    logo: 'svg/nexttv.svg',
    label: 'NextTV',
    vanillaName: 'nexttv',
    analyticsName: 'nexttv',
    selligent_code: 'NXT',
    url: 'nexttv.com',
  },
  {
    id: 'pcg',
    name: 'pcgamer',
    value: 'pcg',
    logo: 'svg/pcgamer.svg',
    label: 'PC Gamer',
    vanillaName: 'pcgamer',
    analyticsName: 'pcg',
    selligent_code: 'PCG',
    url: 'pcgamer.com',
    endpoints: {
      stage: 'https://stage.hawky.pcgamer.com',
      live: 'https://hawky.pcgamer.com',
    },
  },
  {
    id: 'petsradar',
    name: 'petsradar',
    value: 'petsradar',
    logo: 'svg/petsradar.svg',
    label: 'Pets Radar',
    vanillaName: 'petsradar',
    analyticsName: 'petsradar',
    url: 'petsradar.com',
  },
  {
    id: 'space',
    name: 'space',
    value: 'space',
    logo: 'svg/space.svg',
    label: 'Space',
    vanillaName: 'space',
    analyticsName: 'space',
    selligent_code: 'SDC',
    url: 'space.com',
  },
  {
    id: 'techlearning',
    name: 'techlearning',
    value: 'techlearning',
    logo: 'svg/techlearning.svg',
    label: 'Tech Learning',
    vanillaName: 'techlearning',
    analyticsName: 'techlearning',
    selligent_code: 'TEL',
    url: 'techlearning.com',
  },
  {
    id: 'techlearninguniversity',
    name: 'techlearninguniversity',
    value: 'techlearninguniversity',
    logo: 'svg/techlearninguniversity.svg',
    label: 'Tech Learning University',
    vanillaName: 'techlearninguniversity',
    analyticsName: 'techlearninguniversity',
    selligent_code: 'TLU',
    url: 'techlearninguniversity.com',
  },
  {
    id: 'theradar',
    name: 'theradar',
    value: 'theradar',
    logo: 'svg/theradar.svg',
    label: 'The Radar',
    vanillaName: 'theradar',
    analyticsName: 'theradar',
    url: 'theradar.com',
  },
  {
    id: 'tg',
    name: 'tomsguide',
    value: 'tomsguide',
    logo: 'svg/tomsguide.svg',
    label: 'Toms Guide',
    vanillaName: 'tomsguide',
    analyticsName: 'tomsguide',
    selligent_code: 'TSG',
    url: 'tomsguide.com',
    endpoints: {
      stage: 'https://stage.hawk.tomsguide.com',
      live: 'https://hawk.tomsguide.com',
    },
  },
  {
    id: 'th',
    name: 'tomshardware',
    value: 'tomshardware',
    logo: 'svg/tomshardware.svg',
    label: 'Toms Hardware',
    vanillaName: 'tomshardware',
    analyticsName: 'tomshardware',
    selligent_code: 'TSH',
    url: 'tomshardware.com',
    endpoints: {
      stage: 'https://stage.hawk.tomshardware.com',
      live: 'https://hawk.tomshardware.com',
    },
  },
  {
    id: 'ttr',
    name: 'toptenreviews',
    value: 'toptenreviews',
    logo: 'svg/toptenreviews.svg',
    label: 'Top Ten Reviews',
    vanillaName: 'toptenreviews',
    analyticsName: 'toptenreviews',
    selligent_code: 'TTR',
    url: 'toptenreviews.com',
    endpoints: {
      stage: 'https://stage.hawk.toptenreviews.com',
      live: 'https://hawk.toptenreviews.com',
    },
  },
  {
    id: 'trd',
    name: 'techradar',
    value: 'trd',
    logo: 'svg/techradar.svg',
    label: 'Techradar',
    vanillaName: 'techradar',
    analyticsName: 'trd',
    selligent_code: 'TCR',
    url: 'techradar.com',
    endpoints: {
      stage: 'https://stage.hawk.techradar.com',
      live: 'https://hawk.techradar.com',
    },
  },
  {
    id: 'tvtechnology',
    name: 'tvtechnology',
    value: 'tvtechnology',
    logo: 'svg/tvtech.svg',
    label: 'TV Technology',
    vanillaName: 'tvtechnology',
    analyticsName: 'tvtechnology',
    selligent_code: 'TVT',
    url: 'tvtechnology.com',
  },
  {
    id: 'whathifi',
    name: 'whathifi',
    value: 'whathifi',
    logo: 'svg/whathifi.svg',
    label: 'What Hi Fi',
    vanillaName: 'whathifi',
    analyticsName: 'whathifi',
    selligent_code: 'WHI',
    url: 'whathifi.com',
    endpoints: {
      stage: 'https://stage.hawk.whathifi.com',
      live: 'https://hawk.whathifi.com',
    },
  },
  {
    id: 'whattowatch',
    name: 'whattowatch',
    value: 'whattowatch',
    logo: 'svg/whattowatch.svg',
    label: 'What to watch',
    vanillaName: 'whattowatch',
    analyticsName: 'whattowatch',
    selligent_code: 'W2W',
    url: 'whattowatch.com',
  },
  {
    id: 'windowscentral',
    name: 'windowscentral',
    value: 'wp',
    logo: 'svg/windowscentral.svg',
    label: 'Windows Central',
    vanillaName: 'windowscentral',
    analyticsName: 'wp',
    url: 'windowscentral.com',
  },
];
export default sites;
