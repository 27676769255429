import { getText } from '../apiUtils';

export const sendUserEmailVerification = async (email: string): Promise<boolean> => {
  const emailVerificationEndpoint = process.env.KIOSQ_EMAIL_API;
  if (!emailVerificationEndpoint) {
    throw new Error('KIOSQ_EMAIL_API environment variable is not defined');
  }

  const encodedEmail = btoa(email);
  const response = await getText(`${emailVerificationEndpoint}/${encodedEmail}`);

  return response.ok;
};
