import type { Suggestion } from '../../../core/components/GenericComponents/UserInput/types/Suggestion';
import { getSearchAPIEndpoint } from '../../../utils/getSearchAPIEndpoint';
import { GetJSON } from '../apiUtils';

type SuggestionsResponse = {
  models: {
    id: string;
    brand: string;
    name: string;
  }[];
};

export const createModelSuggestionsGetter =
  (getJSON: GetJSON<SuggestionsResponse>) =>
  async (prefix: string, currentSuggestions: Suggestion[]): Promise<Suggestion[]> => {
    if (prefix === '') {
      return currentSuggestions;
    }

    const response = await getJSON(`${getSearchAPIEndpoint()}/suggestions.php`, {
      prefix,
      rows: 5,
    });

    if (response.ok) {
      return response.data.models.map((model) => {
        return {
          id: model.id,
          label: model.brand ? `${model.brand} ${model.name}` : model.name,
        };
      });
    }

    return [];
  };

export type ModelSuggestionsGetter = ReturnType<typeof createModelSuggestionsGetter>;
