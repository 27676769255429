import type { Site } from '../types/Site';

const site: Site = {
  id: '',
  name: '',
  value: '',
  logo: '',
  label: '',
  analyticsName: '',
  url: '',
};
export default site;
