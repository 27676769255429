import { ProductTypes } from '../../../../../../types/ProductType';
import { Deal } from '../../../../../types/Deal';

export const isSignupFirstDeal = ({
  product_type: productType,
  promos,
}: Pick<Deal, 'product_type' | 'promos'>): boolean => {
  const signupFirstPromoTypes = ['signup_first', 'audience'];
  return (
    !!promos?.find((p) => signupFirstPromoTypes.includes(p.type)) ||
    productType === ProductTypes.rewards
  );
};
