enum FeatureType {
  BOOL = 'bool',
  NUMBER = 'number',
  TEXT = 'text',
  DATE_TIME = 'dateTime',
  LIST = 'list',
  JSON = 'json',
}

export default FeatureType;
