import { postJSON } from '../../sharedModules/api/apiUtils';
import generateNumber from '../../utils/generateNumber';
import getUrlParameters from '../../utils/getUrlParameters';

type Metrics = {
  site?: string;
  platform?: string;
  type: 'widgets' | 'hawklinks';
};

const METRICS_PARAM = 'metrics';
const SAMPLE_RATE_PERCENT = 1;

const isMetricsEnabled = (metrics: string | undefined): boolean => {
  if (metrics === 'disabled') {
    return false;
  }
  const parsedMetrics = parseInt(metrics || '', 10);
  if (!isNaN(parsedMetrics) && parsedMetrics > 0) {
    return true;
  }
  return generateNumber(100) < SAMPLE_RATE_PERCENT;
};

const shouldSendMetrics = (): boolean => {
  const { metrics } = getUrlParameters([METRICS_PARAM]);
  return isMetricsEnabled(metrics);
};

const sendMetrics = ({ site, platform, type }: Metrics): void => {
  postJSON(`${process.env.METRICS_ENDPOINT}/send_metrics`, {
    site,
    platform,
    type,
    load_time: parseInt(performance.now().toFixed(0), 10),
    // TODO(Petr): HAWK-7007 Remove unnecessary es6 parameter
    es6: 1,
  });
};

export const captureMetrics = (metrics: Metrics): void => {
  if (shouldSendMetrics()) {
    sendMetrics(metrics);
  }
};
