import React from 'react';

import type { DealData } from '../../../types/DealData';
import type { GenericSharedComponents } from '../../../types/GenericSharedComponents';

import { Provider } from './HawkWidgetProviderContext';
import type { HawkWidgetProviderContext } from './model';

interface HawkWidgetProviderProps {
  dealData: DealData;
  children?: React.ReactNode;
  genericSharedComponents: GenericSharedComponents;
}

const HawkWidgetProvider: React.FC<HawkWidgetProviderProps> = (props) => {
  const {
    children,
    dealData,
    genericSharedComponents: { TrackingPixel },
  } = props;

  return (
    // TODO(Petr): HAWK-7003 VERY unsafe cast!
    //  Works only because props in HawkWidgetProviderContext and WidgetProps (partially) overlap
    //  and because HawkWidgetProvider is always provided with "more" props than it actually
    //  says it needs.
    <Provider value={props as HawkWidgetProviderContext}>
      {children}
      <TrackingPixel dealData={dealData} />
    </Provider>
  );
};

export default HawkWidgetProvider;
