import { AiredaleFeedsGetter } from '../../../../sharedModules/api/airedale/createAiredaleFeedsGetter';
import type { Deal } from '../../../types/Deal';
import type { DealData } from '../../../types/DealData';
import type { SiteWithPlatform } from '../../sites/types/Site';

import { sanitiseAiredaleStringParam } from './sanitiseAiredaleStringParam';

export const enrichDealDataWithAiredaleInfo = async (
  getAiredaleFeeds: AiredaleFeedsGetter,
  dealData: DealData,
  site?: SiteWithPlatform,
): Promise<DealData> => {
  const retailers = [
    ...new Set(
      dealData.deals
        .map((deal) => {
          if (deal.merchant.name) {
            return sanitiseAiredaleStringParam(deal.merchant.name);
          }
          return deal.merchant.name;
        })
        .flat(),
    ),
  ];

  const MIN_AIREDALE_RESULTS = 100;
  const MAX_AIREDALE_RESULTS = retailers.length * 3;

  const airedaleResponse = await getAiredaleFeeds({
    site: site?.vanillaName ?? site?.value,
    length: Math.max(MIN_AIREDALE_RESULTS, MAX_AIREDALE_RESULTS),
    retailerCategory: retailers.join(','),
  });

  return {
    ...dealData,
    deals: dealData.deals.map((deal: Deal) => ({
      ...deal,
      airedale_info: {
        canonicalUrl: airedaleResponse.find(
          (siteInfo) =>
            siteInfo.articlevoucherretailercategory?.toLowerCase() ===
            deal.merchant.name?.toLowerCase(),
        )?.canonical,
      },
    })),
  };
};
