import type { Site } from '../../../core/modules/sites/types/Site';
import { postJSON } from '../apiUtils';

export const sendRewardActivity = async (
  userId: string,
  email: string,
  site: Site,
  sessionId: string,
  productId: string,
): Promise<void> => {
  const endpoint = process.env.REWARD_ACTIVITY_PROCESSOR_ENDPOINT;
  if (!endpoint) {
    throw new Error('REWARD_ACTIVITY_PROCESSOR_ENDPOINT environment variable is not defined');
  }

  postJSON(endpoint, {
    userId,
    email,
    productId,
    sessionId,
    siteCode: site.vanillaName || site.name,
  });
};
