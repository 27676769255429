import { ProductTypes } from '../../types/ProductType';

export const getButtonClassName = (productType: number, isVoucherCodeButton: boolean): string => {
  if (productType === ProductTypes.rewards) {
    return 'vulture-reward';
  }

  if (productType === ProductTypes.unique_code) {
    return 'vulture-code';
  }

  return isVoucherCodeButton ? 'vulture-code' : 'vulture-discount';
};
