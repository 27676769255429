import { getNodes } from './getNodes';
import type { BuildCallbackConfig, UnbuiltPlaceholders } from './hawk/processWidgets/model';
import preparePlaceholders from './preparePlaceholders';

export default async (): Promise<UnbuiltPlaceholders> => {
  const elements = getNodes({ selector: '#hawkshopplaceholder' });
  const callbackToBuildPlaceholder = async ({
    element,
  }: BuildCallbackConfig): Promise<Array<HTMLElement | null>> => {
    const placeholderArray: Array<HTMLElement | null> = [];

    if (!element.querySelector('.hawk-base')) {
      const parts = window.location.pathname.split('/');
      const modelName =
        parts[parts.length - 1].indexOf('shop') === -1 ? parts[parts.length - 1] : 'Mattresses';

      const result = await preparePlaceholders({
        dataset: {
          widgetType: 'shop',
          modelName,
        },
        node: element,
        position: 'end',
      });
      placeholderArray.push(result);
    }
    return placeholderArray;
  };

  return {
    elements,
    callbackToBuildPlaceholder,
  };
};
