const isAllowedParam = (key: string, whiteList?: string[]): boolean =>
  !whiteList || whiteList.includes(key);

const getUrlParameters = (whiteList?: string[]): Partial<Record<string, string>> => {
  if (typeof window === 'undefined') {
    return {};
  }

  const searchParams = new URLSearchParams(window.location.search);
  const filteredParams: Record<string, string> = {};

  searchParams.forEach((value, key) => {
    if (isAllowedParam(key, whiteList)) {
      filteredParams[key] = value;
    }
  });

  return filteredParams;
};

export default getUrlParameters;
