import React from 'react';

import { Translate } from '../../../../../types/Localiser';
import { DialogOverlay } from '../../../DialogOverlay';
import { useHawkWidgetContext } from '../../../HawkWidget/HawkWidgetProviderContext';

import styles from './styles/common.css';
import { Actions, EmailVerificationStates } from './types';

interface VerificationProps {
  userEmail: string;
  siteName: string;
  audienceNoun: string;
  emailVerificationState: EmailVerificationStates;
  translate: Translate;
  actions: Actions;
}

export const Verification: React.FC<VerificationProps> = ({
  translate,
  siteName,
  audienceNoun,
  emailVerificationState,
  userEmail,
  actions,
}) => {
  const { setPopupDeal } = useHawkWidgetContext();

  const handleCloseDialog = (): void => {
    setPopupDeal(null);
  };

  const onClickHandler = (): void => {
    actions.sendVerificationEmail(siteName, userEmail);
  };

  const InteractionButton =
    emailVerificationState === EmailVerificationStates.UNSENT ? (
      <button type="button" onClick={onClickHandler} className={styles.button}>
        {translate('voucherVerificationEmailSend')}
      </button>
    ) : (
      <button type="button" disabled className={styles.button}>
        {emailVerificationState === EmailVerificationStates.SUCCESS
          ? translate('voucherVerificationEmailSent')
          : translate('voucherVerificationEmailError')}
      </button>
    );

  return (
    <DialogOverlay closeDialog={handleCloseDialog}>
      <div className={styles.container}>
        <div className={styles.main}>
          <h3 className={styles.title}>{translate('voucherEmailVerificationTitle')}</h3>
          <p className={styles.text}>
            {translate('voucherEmailVerificationText', [audienceNoun, audienceNoun])}
          </p>
          {InteractionButton}
        </div>
      </div>
    </DialogOverlay>
  );
};
