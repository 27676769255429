import { captureMetrics } from '../../../../hawklinks/browser/metrics';
import { initiateApstagIntegration } from '../../../../sharedModules/apstag/integration';
import type { EventBattle } from '../../../../types/Battle';
import { updateHawk } from '../../../../utils/global';

import { ResolveWidgetsConfig } from './model';
import { IProcessedWidget, processWidget } from './processWidget';
import { sendAppearedEvent } from './sendAppearedEvent';

export const resolveWidgets = async ({
  unprocessedWidgets,
  platformData,
  siteConfig,
  localiser,
  editorialOnPage,
}: ResolveWidgetsConfig): Promise<void> => {
  const processedWidgetsInfo: IProcessedWidget[] = [];

  for (const unprocessedWidget of unprocessedWidgets) {
    if (unprocessedWidget) {
      const index = unprocessedWidgets.indexOf(unprocessedWidget);
      unprocessedWidget.classList.add('hawk-processed');

      /* Ensure we support old widget placeholders, but check before we change
      the classes to avoid reflow */
      if (unprocessedWidget.classList.contains('hawk-placeholder')) {
        unprocessedWidget.classList.remove('hawk-placeholder');
        unprocessedWidget.classList.add('hawk-base');
      }

      if (unprocessedWidget.classList.contains('hawk-nest')) {
        unprocessedWidget.classList.remove('hawk-nest');
        unprocessedWidget.classList.add('hawk-base');
      }

      const newProcessedWidget = await processWidget({
        element: unprocessedWidget,
        siteConfig,
        articleType: platformData.articleType,
        articleCategory: platformData.articleCategory,
        articleId: platformData.articleId,
        keywords: platformData.keywords,
        territory: platformData.territory,
        platform: platformData.platform,
        articleName: document.title,
        articleUrl: document.location.href,
        localiser,
        editorialOnPage,
        index,
      });

      // Only add processed widgets that haven't already been added
      // TODO(Petr): HAWK-7005 How could this happen?
      if (
        newProcessedWidget &&
        processedWidgetsInfo.every(
          (previousProcessedWidget) =>
            previousProcessedWidget.widgetId !== newProcessedWidget.widgetId,
        )
      ) {
        processedWidgetsInfo.push(newProcessedWidget);
      }
    }
  }

  // All widgets have loaded, so store truthy value in widgetsLoaded
  updateHawk({
    widgetsLoaded: 1,
  });

  const battle = processedWidgetsInfo.find((widget) => widget?.props?.battle)?.props?.battle;

  captureMetrics({
    platform: platformData.platform,
    type: 'widgets',
  });

  // Send unified appear event when all widgets are processed
  sendAppearedEvent(platformData.articleId, processedWidgetsInfo, battle as EventBattle);

  initiateApstagIntegration();
};
