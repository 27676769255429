import React from 'react';

import { sendUserEmailVerification } from '../../../../../../../sharedModules/api/kiosq/sendUserEmailVerification';
import { signupToKiosq } from '../../../../../../../sharedModules/api/kiosq/signupToKiosq';
import { CookieKeys, getCookie } from '../../../../../../../sharedModules/getCookie';
import { HawkEvents } from '../../../../../../../types/HawkEvents';
import { Translate } from '../../../../../../types/Localiser';
import { Actions, ActionTypes, Audience, EmailVerificationStates, UserStateAction } from '../types';

import { isKiosqUserUpdated } from './modules/getKiosqState';

const getSignupErrorFromStatus = (translate: Translate, status: number): string => {
  switch (status) {
    case 409:
      return translate('signupErrorExistingUser');
    case 429:
      return translate('signupErrorTooManyRequests');
    case 500:
    default:
      return 'An unknown error occurred, please try again later.';
  }
};

export const getActions = (
  dispatch: React.Dispatch<UserStateAction>,
  translate: Translate,
): Actions => {
  const getSession: Actions['getSession'] = () => {
    const gaCookie = getCookie(CookieKeys.GA);
    dispatch({ type: ActionTypes.GET_SESSION, payload: gaCookie });
  };

  const inputUpdate: Actions['inputUpdate'] = (update) => {
    dispatch({ type: ActionTypes.FORM_INPUT_CHANGE, payload: update });
  };

  const signupUser = async (
    siteName: string,
    terms: boolean,
    email?: string,
    audience?: Audience,
  ): Promise<void> => {
    dispatch({ type: ActionTypes.SIGNUP_INIT });
    if (!email || !terms) {
      return dispatch({
        type: ActionTypes.FORM_ERROR,
        payload: { error: translate('signupSubmitErrorMissingDetails') },
      });
    }

    if (audience && !email.match(audience.regex)) {
      return dispatch({
        type: ActionTypes.FORM_ERROR,
        payload: { error: translate('signupSubmitErrorAudience', [audience.noun]) },
      });
    }

    const signupResponse = await signupToKiosq(email, siteName);
    if (!signupResponse.success) {
      return dispatch({
        type: ActionTypes.FORM_ERROR,
        payload: { error: getSignupErrorFromStatus(translate, signupResponse.status) },
      });
    }

    document.cookie = `${CookieKeys.KIOSQ}=${signupResponse.cookie}`;
    window.dispatchEvent(new Event(HawkEvents.KIOSQ_COOKIE_CHANGE));
    const isUserStateUpdated = await isKiosqUserUpdated();
    if (!isUserStateUpdated) {
      return dispatch({
        type: ActionTypes.FORM_ERROR,
        payload: { error: translate('missingKiosqSessionError') },
      });
    }

    return getSession();
  };

  const sendVerificationEmail = async (siteName: string, email?: string): Promise<void> => {
    if (email) {
      dispatch({
        type: ActionTypes.SET_EMAIL_SENDING,
        payload: EmailVerificationStates.LOADING,
      });
      const success = await sendUserEmailVerification(email);
      dispatch({
        type: ActionTypes.SET_EMAIL_SENDING,
        payload: success ? EmailVerificationStates.SUCCESS : EmailVerificationStates.FAILURE,
      });
    }
  };

  return React.useMemo<Actions>(
    () => ({
      getSession,
      inputUpdate,
      signupUser,
      sendVerificationEmail,
    }),
    [],
  );
};
