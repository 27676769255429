import type { APIData } from '../../../core/types/APIData';
import type { BattleTest } from '../../../core/types/Battle';
import { QueryParams } from '../../../utils/encodeQueryString';
import { getSearchAPIEndpoint } from '../../../utils/getSearchAPIEndpoint';
import { ApiResponse, GetJSON } from '../apiUtils';

export type WidgetResponse = {
  widget: {
    data: APIData;
  };
  battle: BattleTest | null;
  area: string | null;
  models: Record<string, number>;
  version: string;
};

export const createWidgetResponseGetter = (getJSON: GetJSON<WidgetResponse>) => {
  return async (params?: QueryParams): Promise<ApiResponse<WidgetResponse>> => {
    const response = await getJSON(`${getSearchAPIEndpoint()}/widget.php`, params);
    // TODO(Petr): HAWK-7012 Very basic API data validation to satisfy existing tests.
    //  Add proper schema validation later if we decide we don't trust Search API.
    if (response.ok && !response.data.widget.data) {
      return {
        ok: false,
        status: 0,
        detail: 'Invalid API data format',
      };
    }
    return response;
  };
};

export type WidgetResponseGetter = ReturnType<typeof createWidgetResponseGetter>;
