import { postJSON } from '../apiUtils';

type SignupResponse = {
  cookie: string;
};

export const signupToKiosq = async (
  email: string,
  site: string,
): Promise<
  | {
      success: true;
      status: number;
      cookie: string;
    }
  | {
      success: false;
      status: number;
    }
> => {
  const signupEndpoint = process.env.KIOSQ_SIGN_UP_API;
  if (!signupEndpoint) {
    throw new Error('KIOSQ_SIGN_UP_API environment variable is not defined');
  }

  const response = await postJSON<SignupResponse>(signupEndpoint, {
    site,
    email,
  });

  if (!response.ok) {
    return { success: false, status: response.status };
  }

  return {
    success: true,
    status: response.status,
    cookie: response.data.cookie,
  };
};
