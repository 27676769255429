import { isStorybook } from '../../utils/isStorybook';

export default (url: string): string => {
  // In Storybook
  // https://storybook.js.org/docs/configure/integration/images-and-assets?renderer=react#serving-static-files-via-storybook-configuration
  if (isStorybook) {
    return `./img/${url}`;
  }
  // In development
  if (process.env.NODE_ENV === 'development') {
    return `/public/img/${url}`;
  }
  // In production
  return `${process.env.ASSETS_ENDPOINT}/img/${url}`;
};
