import { sendFreyrEvent } from '../../../../analytics-library/entry';
import { AnalyticsData } from '../../../../types/Analytics';
import type { EventBattle } from '../../../../types/Battle';

import { IProcessedWidget } from './processWidget';

export const sendAppearedEvent = (
  articleId: string | undefined,
  processedWidgets: IProcessedWidget[],
  battle: EventBattle,
): void => {
  // TODO(Petr): HAWK-7005 Remove `if`, since processedWidgets are always truthy
  if (processedWidgets) {
    const eventLabel = processedWidgets.reduce((acc, widget) => {
      if (acc[widget.widgetType]) {
        return {
          ...acc,
          [widget.widgetType]: acc[widget.widgetType] + 1,
        };
      }

      return {
        ...acc,
        [widget.widgetType]: 1,
      };
    }, {});

    const gaData = {
      event: {
        category: 'Affiliates',
        type: 'Hawk appeared',
        label: JSON.stringify(eventLabel),
        articleId,
        battle,
        totalDeals: processedWidgets?.length ?? 0,
      },
    };

    sendFreyrEvent(gaData as AnalyticsData, false);
  }
};
