import type Localiser from '../../../../core/modules/Localiser';
import type { Site } from '../../../../core/modules/sites/types/Site';
import { WidgetProps } from '../../../../core/types/WidgetProps';
import { renderWidget } from '../../renderWidget';

export interface IProcessedWidget {
  widgetId: string;
  widgetType: string;
  props: WidgetProps;
}

interface IProcessWidgetConfig {
  articleCategory?: string;
  articleId?: string;
  articleName: string;
  articleType?: string;
  articleUrl: string;
  editorialOnPage: boolean;
  element: HTMLElement;
  keywords?: string[];
  localiser: Localiser;
  platform: string;
  siteConfig: Site;
  territory: string;
  index: number;
}

export const processWidget = async (
  config: IProcessWidgetConfig,
): Promise<IProcessedWidget | null> => {
  const {
    articleCategory,
    articleId,
    articleName,
    articleType,
    articleUrl,
    editorialOnPage,
    element,
    keywords,
    localiser,
    platform,
    siteConfig,
    territory,
    index,
  } = config;
  /* Ignore widgets without a widget type & set up a new index for each rendered widget
  This ensures we generate a different id if there are duplicate widgets on the page
  (e.g. 2 editorial widgets for same model & widget type) */
  if (element.getAttribute('data-widget-type')) {
    const newIndex: number = (index || 0) + 1;

    return renderWidget({
      articleCategory,
      articleId,
      articleName,
      articleType,
      articleUrl,
      cacheBust: false,
      editorialOnPage,
      el: element,
      index: newIndex,
      keywords,
      localiser,
      now: undefined,
      platform,
      site: siteConfig,
      territory,
    });
  }

  return null;
};
