import { AnalyticsData } from '../../types/Analytics';
import { getHawk, updateHawk } from '../../utils/global';
import getFreyrData from '../getFreyrData';
import sendFreyr from '../sendFreyr';

const sendFreyrEvent = async (data: AnalyticsData, skip?: boolean): Promise<void> => {
  const freyrData = getFreyrData(data);

  // Store the data in the global object so we can check it in cypress tests
  // This is done here so that it is only used for client side GA - setting it globally in node would cause the memory to increase over time
  const { analytics } = getHawk();
  updateHawk({
    analytics: {
      freyr: (analytics?.freyr || []).concat(freyrData),
    },
  });

  // Store the analytics data, but don't send the event in some instances (e.g. console)
  if (!skip) {
    sendFreyr(freyrData);
  }
};

export { sendFreyrEvent };
