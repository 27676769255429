import React from 'react';

export interface ImgParams {
  src: string;
  srcSet?: string;
  alt?: string;
  width?: number;
  height?: number;
  className?: string;
  source?: JSX.Element;
  onClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  attributes?: Record<string, string>;
  serversideImages: boolean;
  container: React.MutableRefObject<HTMLImageElement | null>;
  inputContainer: React.MutableRefObject<HTMLInputElement | null>;
  element: JSX.Element | null;
  isImgLazyLoadSupported: boolean;
  isServerside: boolean;
}

export enum ImgUrl {
  NO_IMAGE = 'misc/no-image-available.svg',
}
