import React from 'react';

import { ClickType } from '../../../../sharedModules/getClickType';
import type { Event } from '../../../../types/Analytics';
import { FreyrEventPrefix } from '../../../../types/FreyrEventPrefix';
import type { Product } from '../../../../types/Product';
import type { ReviewData } from '../../../types/ModelInfo';
import { useHawkWidgetContext } from '../HawkWidget/HawkWidgetProviderContext';
import StarRating from '../StarRating/StarRating';
import { TargetEnum } from '../TrackedLink/model';

import { StreamingScore } from './StreamingScore';
import styles from './styles/model-code-review.css';

interface ModelCodeReviewProps {
  review: ReviewData;
  asinLabelSuffix?: string;
  className?: string;
}

const ModelCodeReview: React.FC<ModelCodeReviewProps> = ({
  review,
  asinLabelSuffix,
  className,
}) => {
  const linkRef = React.useRef<HTMLAnchorElement | null>(null);

  const {
    translate,
    sendAnalytics,
    defaultTab,
    genericSharedComponents: { TrackedLink },
  } = useHawkWidgetContext();

  const getLabel = (): string => {
    switch (review.code_name) {
      case 'asin':
        if (className === 'userReviews') {
          return translate('modelCodeReviewUserReviewsDefault', ['Amazon']);
        }
        if (asinLabelSuffix) {
          return `${review.review_count} ${asinLabelSuffix}`;
        }
        if (review.review_count === 1) {
          return translate('modelCodeReviewASINLabelSingular', [String(review.review_count)]);
        }
        return translate('modelCodeReviewASINLabel', [String(review.review_count)]);
      case 'walmart':
        if (className === 'userReviews') {
          return translate('modelCodeReviewUserReviewsDefault', ['Walmart']);
        }
        if (review.review_count === 1) {
          return translate('modelCodeReviewWalmartLabelSingular', [String(review.review_count)]);
        }
        return translate('modelCodeReviewWalmartLabel', [String(review.review_count)]);
      default:
        return translate('modelCodeReviewDefaultLabel', [String(review.review_count)]);
    }
  };

  const trackLinkClick = (): void => {
    if (sendAnalytics) {
      const label = getLabel();
      sendAnalytics(
        {
          label,
          clickType: ((): string => {
            // Click type
            if (review) {
              switch (review.code_name) {
                case 'asin':
                  return ClickType.AMAZON_STAR_RATING;
                case 'walmart':
                  return ClickType.WALMART_STAR_RATING;
                default:
                  return review.code_name;
              }
            }
            return '';
          })(),
          prefix: FreyrEventPrefix.CLICK_FROM,
        } as Event, // TODO: replace after fie-analytics TS is checked
        null,
        {
          url: review.reviews_url,
          customTrackingId: review.reviews_customTrackingId,
        } as Product, // TODO: replace after fie-analytics TS is checked
      );
    }
  };

  const containerStyle = className && styles[className] ? className : 'container';

  const streamingScores = ['imdb', 'rt'];

  if (review) {
    return (
      <div className={styles[containerStyle]}>
        {defaultTab?.category !== 'multimedia' && !streamingScores.includes(review.code_name) && (
          <>
            <TrackedLink
              {...{
                trackLinkClick,
                customTarget: TargetEnum.BLANK,
                attributes: {
                  ref: linkRef,
                  className: styles['link'],
                  href: review.reviews_url,
                },
              }}
            >
              {getLabel()}
            </TrackedLink>
            <StarRating
              {...{
                score: review && review.review_score_formatted,
                className,
                rating: review.code_name,
              }}
            />
          </>
        )}
        {(defaultTab?.category === 'multimedia' || streamingScores.includes(review.code_name)) && (
          <StreamingScore
            {...{
              review,
              key: review.code_name,
            }}
          />
        )}
      </div>
    );
  }
  return null;
};

export default ModelCodeReview;
