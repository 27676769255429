import { AnalyticsData, Event } from '../../types/Analytics';
import { ResultBattle } from '../../types/Battle';
import { LabelPromo } from '../../types/LabelPromo';
import { Product } from '../../types/Product';
import { Review } from '../../types/Review';

export default class BaseFormatter {
  getEventCategory(data: AnalyticsData): string {
    return this.getEvent(data).category;
  }

  getEvent(data: AnalyticsData): Event {
    return data.event;
  }

  getEventPrefix(data: AnalyticsData): string {
    return this.getEvent(data).prefix;
  }

  getEventFlag(data: AnalyticsData): string {
    return this.getEvent(data).flag;
  }

  getEventProductType(data: AnalyticsData): string {
    return this.getEvent(data).productType;
  }

  getEventComponentName(data: AnalyticsData): string {
    const event = this.getEvent(data);
    return event.component && event.component.name;
  }

  getEventComponentCategory(data: AnalyticsData): string {
    const event = this.getEvent(data);
    return event.component && event.component.category;
  }

  getEventType(data: AnalyticsData): string {
    return this.getEvent(data).type;
  }

  getEventLabel(data: AnalyticsData): string {
    return this.getEvent(data).label;
  }

  getProducts(data: AnalyticsData): Product[] {
    return data.products || [];
  }

  getMatchIds(data: AnalyticsData): (number | null)[] {
    return this.getProducts(data).map((p) => p.matchId);
  }

  getMerchantNames(data: AnalyticsData): Array<string | null> {
    return this.getProducts(data).map((p) => p.merchant && p.merchant.name);
  }

  getMerchantIds(data: AnalyticsData): Array<number | null> {
    return this.getProducts(data).map((p) => p.merchant && p.merchant.id);
  }

  getMerchantUrls(data: AnalyticsData): Array<string | null> {
    return this.getProducts(data).map((p) => p.merchant && p.merchant.url);
  }

  getMerchantAffiliateNetwork(data: AnalyticsData): Array<string | null> {
    return this.getProducts(data).map((p) => p.merchant && p.merchant.network);
  }

  getModelIds(data: AnalyticsData): Array<number | null> {
    return this.getProducts(data).map((p) => p.model && p.model.id);
  }

  getModelBrands(data: AnalyticsData): Array<string | null> {
    return this.getProducts(data).map((p) => (p.model && p.model.brand ? p.model.brand : null));
  }

  getModelNames(data: AnalyticsData): Array<string | null> {
    return this.getProducts(data).map((p) => p.model && p.model.name);
  }

  getModelParentNames(data: AnalyticsData): Array<string | null> {
    return this.getProducts(data).map((p) => (p.model && p.model.parent ? p.model.parent : null));
  }

  getIndex(data: AnalyticsData): number | null {
    return this.getEvent(data).index;
  }

  getTotalDeals(data: AnalyticsData): number {
    return this.getEvent(data).totalDeals;
  }

  getCurrencyIso(data: AnalyticsData): string[] {
    return this.getProducts(data).map((p) => p.currencyIso);
  }

  getRetailPrices(data: AnalyticsData): number[] {
    return this.getProducts(data).map((p) => p.price);
  }

  getWasPrices(data: AnalyticsData): Array<number | null> {
    return this.getProducts(data).map((p) => p.wasPrice);
  }

  getServiceProviders(data: AnalyticsData): Array<string | null> {
    return this.getProducts(data).map((p) => p.serviceProvider);
  }

  getProductNames(data: AnalyticsData): string[] {
    return this.getProducts(data).map((p) => p.name);
  }

  getBattleData(data: AnalyticsData): ResultBattle | null {
    const { battle } = this.getEvent(data);

    if (battle && battle.test && battle.variant) {
      return {
        origin: 'battle',
        t_name: battle.test.name,
        t_id: battle.test.id,
        v_id: battle.variant.id,
        v_name: battle.variant.name,
      };
    }
    return null;
  }

  getVoucherCodeString(data: AnalyticsData): string | null {
    const products = this.getProducts(data);
    return products.length > 0 ? products.map((p) => p.voucherCodeString || null)[0] : null;
  }

  getCustomTrackingIds(data: AnalyticsData): Array<string | null> {
    return this.getProducts(data).map((p) => p.customTrackingId);
  }

  getLabels(data: AnalyticsData): Array<LabelPromo[]> {
    return this.getProducts(data).map((p) => p.labels);
  }

  getPromos(data: AnalyticsData): Array<LabelPromo[]> {
    return this.getProducts(data).map((p) => p.promos);
  }

  getPreorder(data: AnalyticsData): boolean[] {
    return this.getProducts(data).map((p) => p.preorder);
  }

  getUrls(data: AnalyticsData): string[] {
    return this.getProducts(data).map((p) => p.url);
  }

  getElementIds(data: AnalyticsData): string[] {
    return this.getEvent(data).elementIds || [];
  }

  getElementYPositionAsNumber(data: AnalyticsData): number | null {
    return this.getEvent(data).elementYPosition;
  }

  getStartDate(data: AnalyticsData): Array<number | null> {
    return this.getProducts(data).map((p) => p.startDate);
  }

  getScope(data: AnalyticsData): Array<string | null> {
    return this.getProducts(data).map((p) => p.scope);
  }

  getTimeRemaining(data: AnalyticsData): number | null {
    return this.getProducts(data).map((p) => p.timeRemaining)[0];
  }

  getClickType(data: AnalyticsData): string {
    return this.getEvent(data).clickType;
  }

  getProductTypes(data: AnalyticsData): string[] {
    return this.getProducts(data).map((p) => p.type);
  }

  getReviews(data: AnalyticsData): Review[] {
    return data.reviews || [];
  }

  getReviewCodes(data: AnalyticsData): string[] {
    return this.getReviews(data).map((r) => r.code);
  }

  getReviewCounts(data: AnalyticsData): number[] {
    return this.getReviews(data).map((r) => r.count);
  }

  getReviewScores(data: AnalyticsData): number[] {
    return this.getReviews(data).map((r) => r.score);
  }

  getLoadTimeAsNumber(): number {
    if (process.env.NODE_ENV !== 'test' && typeof window !== 'undefined') {
      return parseInt(performance.now().toFixed(0), 10);
    }
    return 0;
  }

  getViewportTime(data: AnalyticsData): number {
    return this.getEvent(data).viewportTime;
  }

  getPageHeightAsNumber(): number {
    return typeof document !== 'undefined' ? document.body.clientHeight : 0;
  }

  getBackgroundColor(data: AnalyticsData): string {
    return this.getEvent(data).backgroundColor;
  }

  getBlockLayout(data: AnalyticsData): string | null {
    return this.getEvent(data).blockLayout;
  }

  getAreaClicked(data: AnalyticsData): string {
    return this.getEvent(data).areaClicked;
  }
}
