import React from 'react';

const Fallback = ({ serverSideHtml }: { serverSideHtml?: string }): JSX.Element | null => {
  if (serverSideHtml) {
    // We can disable this warning because we are sure its sanitized HTML
    // eslint-disable-next-line react/no-danger
    return <div dangerouslySetInnerHTML={{ __html: serverSideHtml }} />;
  }

  return null;
};

export default Fallback;
