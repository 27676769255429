import React, { useEffect } from 'react';

import { ClickType } from '../../../../sharedModules/getClickType';
import type { Event } from '../../../../types/Analytics';
import { FreyrEventPrefix } from '../../../../types/FreyrEventPrefix';
import { useHawkWidgetContext } from '../HawkWidget/HawkWidgetProviderContext';

import { getActions } from './getActions';
import type { ScrollerProps } from './model';
import { initialState, reducer } from './reducer';
import ScrollerArrow from './ScrollerArrow';
import ScrollerPage from './ScrollerPage';
import styles from './styles/scroller.css';

export const Scroller: React.FC<ScrollerProps> = (props) => {
  const {
    autoShuffle,
    items,
    loadMoreItems,
    totalItems,
    deal,
    children,
    className,
    pagesLength,
    pages,
  } = props;

  const [state, dispatch] = React.useReducer(reducer, initialState);

  const actions = getActions(dispatch);

  const { sendAnalytics, widget } = useHawkWidgetContext();

  const sendGA = (clickType: ClickType): void => {
    if (sendAnalytics) {
      sendAnalytics(
        {
          clickType,
          prefix: FreyrEventPrefix.CLICK_FROM,
        } as Event, // TODO: remove after we check sendAnalytics type in fie-analytics
        deal,
      );
    }
  };

  useEffect(() => {
    if (autoShuffle) {
      const shuffle = window.setInterval(() => {
        const { currentPage, currentStop } = state;
        const newPage = currentPage + 1;
        if (!currentStop) {
          actions.setCurrentPage(newPage <= pagesLength - 1 ? newPage : 0);
        }
        if (currentStop) {
          clearInterval(shuffle);
        }
      }, 5000);
    }
  }, []);

  const goToPreviousPage = (e): void => {
    e.stopPropagation();
    const { currentPage } = state;
    const newPage = currentPage - 1;

    actions.setCurrentPage(newPage >= 0 ? newPage : currentPage);
    actions.setCurrentStop(true);
    if (newPage >= 0) {
      sendGA(ClickType.SCROLL_TO_PREVIOUS_PAGE);
    }
  };

  const goToNextPage = async (e): Promise<void> => {
    e.stopPropagation();
    const { currentPage } = state;
    const newPage = currentPage + 1;

    actions.setCurrentPage(newPage <= pagesLength - 1 ? newPage : currentPage);
    actions.setCurrentStop(true);
    // Load more, but not if we have already loaded more
    // e.g. the user navigated to page, then went back to page 0, before loading page 1 again
    if (
      loadMoreItems &&
      newPage === pagesLength - 1 &&
      totalItems &&
      totalItems > (items || []).length
    ) {
      await loadMoreItems(false);
    }

    if (newPage <= pagesLength - 1) {
      sendGA(ClickType.SCROLL_TO_NEXT_PAGE);
    }
  };

  /** @param {React.TouchEvent} event */
  const onTouchStart = (event): void => {
    const swipeX = event.changedTouches[0].pageX;
    actions.setCurrentSwipeX(swipeX);
  };

  /** @param {React.TouchEvent} event */
  const onTouchEnd = async (event): Promise<void> => {
    // Get the X coordinate of the touch event
    const newSwipeX = event.changedTouches[0].pageX;
    const { currentSwipeX } = state;

    // We need to know the direction of the swipe so we know which page to go to
    if (typeof currentSwipeX === 'number') {
      if (newSwipeX < currentSwipeX) {
        if (currentSwipeX - newSwipeX >= 100) {
          await goToNextPage(event);
        }
      } else if (newSwipeX > currentSwipeX) {
        if (newSwipeX - currentSwipeX >= 100) {
          goToPreviousPage(event);
        }
      }
    }
  };

  const getPercentage = (): number => {
    if (pagesLength > 1) {
      if (className === 'magazinesubscriptions' || className === 'partner') {
        return 100;
      }
      if (className === 'contract-details') {
        return 100;
      }
      return 90;
    }
    return 100;
  };

  const { currentPage } = state;
  return (
    <div
      className={className && styles[className] ? styles[className] : styles['container']}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
    >
      {pagesLength > 1 && (
        <ScrollerArrow
          direction="left"
          disabled={currentPage === 0}
          onClick={goToPreviousPage}
          className={className}
        />
      )}
      <div
        className={styles['pages']}
        style={{ gridTemplateColumns: `repeat(${pagesLength}, ${getPercentage()}%)` }}
      >
        {React.Children.map(children, (child, index) => {
          if (!React.isValidElement(child)) {
            return null;
          }
          return (
            <ScrollerPage
              active={index === currentPage}
              currentPage={currentPage}
              className={className}
              key={JSON.stringify(child.props.items)}
            >
              {child}
            </ScrollerPage>
          );
        })}
        {widget.id === 'partner' && (
          <ul className={styles.dots} style={{ marginBottom: '0' }}>
            {pagesLength > 1 &&
              pages?.map((page, index) => {
                return (
                  <li
                    className={index === currentPage ? styles.black : styles.grey}
                    key={pages.indexOf(page)}
                    style={{
                      fontSize: '40px',
                      width: '12px',
                      padding: '0 5px',
                    }}
                  />
                );
              })}
          </ul>
        )}
      </div>
      {pagesLength > 1 && (
        <ScrollerArrow
          direction="right"
          disabled={currentPage === pagesLength - 1}
          onClick={goToNextPage}
          className={className}
        />
      )}
    </div>
  );
};
