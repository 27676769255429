import { Deal } from '../../core/types/Deal';

export const supportedMerchant = 'amazon';

export enum apstagAttributes {
  asin = 'data-aps-asin',
  tag = 'data-aps-asc-tag',
  ascsubtag = 'data-aps-asc-subtag',
}

export const defaultApstagAttributes: { [key: string]: string | null } = {
  [apstagAttributes.asin]: null,
  [apstagAttributes.tag]: null,
  [apstagAttributes.ascsubtag]: null,
};

const getAsinFromUrl = (amazonURL: URL): string => {
  const amazonPathParts = amazonURL.pathname.split('/');
  let desiredIndex = -1;
  for (let index = 0; index < amazonPathParts.length; index++) {
    const part = amazonPathParts[index];
    if (index === desiredIndex) {
      return amazonPathParts[index];
    }
    if (part.toLowerCase() === 'dp') {
      desiredIndex = index + 1;
    }
  }

  return '';
};

const getAmazonAttributesFromUrl = (amazonUrl: URL): { [key: string]: string | null } => {
  const newAttributes = { ...defaultApstagAttributes };
  const asin = getAsinFromUrl(amazonUrl);
  if (asin) {
    newAttributes[apstagAttributes.asin] = asin;
  }

  const attributeKeys = Object.keys(apstagAttributes);
  amazonUrl.searchParams.forEach((amazonURLSearchParamValue, amazonURLSearchParamKey) => {
    if (attributeKeys.includes(amazonURLSearchParamKey)) {
      newAttributes[apstagAttributes[amazonURLSearchParamKey]] = amazonURLSearchParamValue;
    }
  });

  return newAttributes;
};

export const getAmazonApstagAttributesForHref = (
  href: string,
): { [key: string]: string | null } => {
  const amazonRegex = /amazon\.com(\/|$)/;
  try {
    const hrefUrl = new URL(href);
    const { searchParams, hostname } = hrefUrl;
    // if the hrefs domain is amazon, then this isn't a affiliated link,
    // as such we can get the data from this directly
    if (hostname.match(amazonRegex)) {
      return getAmazonAttributesFromUrl(hrefUrl);
    }

    // the domain of this link isn't amazon, therefore it must be an affiliate link,
    // as such we need to search the affiliate links query parameters for the amazon url
    // and extract the data from that
    for (const value of searchParams.values()) {
      if (value.match(amazonRegex)) {
        return getAmazonAttributesFromUrl(new URL(value));
      }
    }
  } catch (err: unknown) {
    // We need to catch any errors where the href isn't a valid URL,
    // this could be where it is relative or a mail link
  }

  // If we don't find an amazon url or there is an error, remove the attributes
  return defaultApstagAttributes;
};

export const getAsinFromDeal = (deal?: Deal): string | null => {
  return deal?.merchant.name?.toLowerCase().includes(supportedMerchant) &&
    deal.product_key.includes('-')
    ? deal.product_key.split('-')[1]
    : null;
};
