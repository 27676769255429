import { Bid, Bids } from '../../core/types/Apstag';
import { createTimeOutedInterval } from '../createTimeOutedInterval/createTimeOutedInterval';

import { apstagAttributes } from './getAttributes';

export const isApstagQueueAvailable = (): boolean => {
  return !!window.apstag?.queue;
};

const updateStoreWithBidRequestsMade = (): void => {
  if (window.futureNCA) {
    for (const [asin, bid] of Object.entries(window.futureNCA.bids as Bids)) {
      window.futureNCA.bids[asin] = { ...bid, isRequestMade: true };
    }
  }
};

export const apstagUpdateClickHook = (url: string, element: HTMLElement): string => {
  const asin = element?.getAttribute(apstagAttributes.asin);
  if (asin && url && window.futureNCA) {
    window.futureNCA.bids[asin] = { isRequestMade: true, href: url };
    return url;
  }

  return element?.getAttribute('href') || '';
};

export const apstagCallback = (): void => {
  window.apstag
    .nativeContent?.()
    .registerHook('nativeContent.updateClickUrl', apstagUpdateClickHook);
  window.apstag.nativeContent?.().enable();
};

export const pushApstagTrigger = (): void => {
  const { apstag } = window;

  apstag.queue.push(apstagCallback);
  updateStoreWithBidRequestsMade();
};

const defaultNcaWindowObject = {
  isListenerAdded: false,
  bids: {},
};

const initiateNcaWindowObject = (): void => {
  window.futureNCA = window.futureNCA || defaultNcaWindowObject;
};

// We want to initialize the window object if it isn't already created
// and then if this is being run after the document is ready, there's no need to wait
// however we should batch all our initial calls before the dom is ready into 1 run of the NCA code
export const triggerApstag = (): void => {
  initiateNcaWindowObject();
  if (document.readyState === 'complete') {
    pushApstagTrigger();
    return;
  }

  if (window.futureNCA && !window.futureNCA?.isListenerAdded) {
    window.addEventListener('load', pushApstagTrigger);
    window.futureNCA.isListenerAdded = true;
  }
};

export const initiateApstagIntegration = (): void => {
  // There's an edge case where we run this code on a page which is using the old ad integration
  // which contains apstag but not the new property
  // or where the bordeaux script has not been loaded/run on the page yet
  if (isApstagQueueAvailable()) {
    triggerApstag();
  }

  createTimeOutedInterval(isApstagQueueAvailable, triggerApstag);
};

export const getAsinBidStore = (asin?: string | null): Bid | undefined | null => {
  return asin ? window.futureNCA?.bids[asin] : null;
};

export const addAsinToBidStore = (asin: string): void => {
  initiateNcaWindowObject();
  if (window.futureNCA && !window.futureNCA.bids[asin]) {
    window.futureNCA.bids[asin] = {
      isRequestMade: false,
      href: '',
    };
  }
};
