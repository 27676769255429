import { WidgetId } from '../../../types/Widget';
import { ModelWidgetData } from '../types';

import { sanitiseAiredaleStringParam } from './sanitiseAiredaleStringParam';

const shouldFetchAiredaleInfo = (widgetType: string, isMerchantPage: boolean): boolean => {
  if (
    (widgetType !== WidgetId.VULTURE &&
      widgetType !== WidgetId.EAGLE &&
      widgetType !== WidgetId.TILE &&
      widgetType !== WidgetId.SCROLLABLE_TILE) ||
    isMerchantPage
  ) {
    return false;
  }

  return true;
};

export const enrichModelWidgetWithAiredaleInfo = async (
  setupModelWidgetData: ModelWidgetData,
): Promise<ModelWidgetData> => {
  if (
    !shouldFetchAiredaleInfo(
      setupModelWidgetData.options.props.widgetType,
      Boolean(setupModelWidgetData.options.props.params.filter_merchant_name),
    )
  ) {
    return setupModelWidgetData;
  }

  const retailers = [
    ...new Set(
      setupModelWidgetData.model.tabConfigs
        .map((tab) => {
          return setupModelWidgetData.model.tabData[tab.value].offers.map((offer) => {
            if (offer.merchant.name) {
              return sanitiseAiredaleStringParam(offer.merchant.name);
            }
            return offer.merchant.name;
          });
        })
        .flat(),
    ),
  ];

  if (!retailers.length) {
    return setupModelWidgetData;
  }

  const MIN_AIREDALE_RESULTS = 100;
  const MAX_AIREDALE_RESULTS = retailers.length * 3;

  const airedaleResponse = await setupModelWidgetData.options.props.getAiredaleFeeds({
    site:
      setupModelWidgetData.options.props.site.vanillaName ??
      setupModelWidgetData.options.props.site.value,
    length: Math.max(MIN_AIREDALE_RESULTS, MAX_AIREDALE_RESULTS),
    retailerCategory: retailers.join(','),
    territory: setupModelWidgetData.options.props.territory,
  });

  return {
    ...setupModelWidgetData,
    model: {
      ...setupModelWidgetData.model,
      tabData: {
        ...setupModelWidgetData.model.tabConfigs.reduce((acc, tab) => {
          return {
            ...acc,
            [tab.value]: {
              ...setupModelWidgetData.model.tabData[tab.value],
              offers: setupModelWidgetData.model.tabData[tab.value].offers.map((offer) => {
                return {
                  ...offer,
                  airedale_info: {
                    canonicalUrl: airedaleResponse.find(
                      (siteInfo) =>
                        siteInfo.articlevoucherretailercategory?.toLowerCase() ===
                        offer.merchant.name?.toLowerCase(),
                    )?.canonical,
                  },
                };
              }),
            },
          };
        }, {}),
      },
    },
  };
};
