import { getFormattedPrice } from '../../../sharedModules/getFormattedPrice';
import type { AnalyticsData, Event } from '../../../types/Analytics';
import type { EventBattle } from '../../../types/Battle';
import { FreyrEventPrefix } from '../../../types/FreyrEventPrefix';
import type { Model } from '../../../types/Model';
import type { Product } from '../../../types/Product';
import { getFlagPromos } from '../../modules/getPromos';
import type { Deal, Label, Promo } from '../../types/Deal';
import type { DealData } from '../../types/DealData';
import type { ModelExtra, ReviewData } from '../../types/ModelInfo';
import { WidgetId } from '../../types/Widget';
import type { Widget } from '../../types/Widget';
import getPriceType from '../../utils/getPriceType';

import { cacheValue } from './cacheValue';
import { calculateViewportTime } from './calculateViewportTime';
import { getCachedValue } from './getCachedValue';
import { getGATitle } from './getGATitle';

interface ICurrentAnalytics {
  widget: Widget;
  widgetId: string;
  editorial: boolean;
  name: string | null;
  articleId: string;
  reviews: ReviewData[];
  battle: EventBattle | null;
  placeholder: HTMLElement | null;
  language: string;
  star: string | null;
  viewportTime: number;
  viewportEnterTime: number | null;
  blockLayout: string | null;
}

export default (
  event: Event | null,
  product: Product | null,
  dealData: DealData,
  deal: Deal | null,
  {
    widget,
    widgetId,
    editorial,
    name,
    articleId,
    reviews = [],
    battle,
    placeholder,
    language,
    star,
    viewportTime,
    viewportEnterTime,
    blockLayout,
  }: ICurrentAnalytics,
): AnalyticsData => {
  const dataset = placeholder && placeholder.dataset;
  const deals = dealData && dealData.deals ? dealData.deals : [];
  const widgetType =
    star === 'hero' && widget.id === WidgetId.DEAL ? `Star ${widget.gaLabel}` : widget.gaLabel;

  const voucherCodeString =
    deal && ((deal && deal['promos']) || []).find((p) => p.type === 'voucher_code');

  const gaTitle = getGATitle(
    deal?.product_type ||
      (dealData && dealData.deals && dealData.deals[0] ? dealData.deals[0].product_type : 0),
  );
  const gaData: AnalyticsData = {
    event: {
      category: 'Affiliates',
      prefix: '', // This should be (optionally) set via the passed event object
      flag: editorial ? 'Editor' : '',
      productType: gaTitle,
      component: {
        name: name || widgetType,
        category: widget.id,
      },
      type: '', // This should be (optionally) set via the passed event object
      clickType: '',
      label: ((): string => {
        if (deal) {
          return deal.model_matched.join(',');
        }

        if (deals.length > 0) {
          // Construct an array of model names, based on the contents of model_matched for each deal
          const modelNames: Set<string> = new Set();
          deals.forEach((deal) => {
            (deal.model_matched || []).forEach((modelName) => modelNames.add(modelName));
          });
          return Array.from(modelNames).join(',');
        }
        if (dataset && dataset.modelName) {
          return dataset.modelName.replace(/,/g, '|');
        }
        return '';
      })(),
      index: event && typeof event.index === 'number' ? event.index : null, // This will be passed and updated as part of the event parameter
      totalDeals: deal ? 1 : deals.length,
      viewportTime: calculateViewportTime(
        viewportEnterTime || new Date().getTime(),
        viewportTime || 0,
      ),
      battle,
      articleId,
      backgroundColor: ((): string => {
        const cachedValue = getCachedValue('backgroundColor');

        if (cachedValue) {
          return String(cachedValue);
        }

        if (typeof document !== 'undefined') {
          const { backgroundColor } = getComputedStyle(document.body);
          cacheValue('backgroundColor', backgroundColor);
          return String(backgroundColor);
        }

        return '';
      })(),
      blockLayout,
      areaClicked: '',
      elementIds: [widgetId],
      elementYPosition: ((): number | null => {
        /* Only send the elementYPosition when there is a "Click from" prefix to
        avoid intensive computation on load, which increases FID */
        if (event && event.prefix === FreyrEventPrefix.CLICK_FROM) {
          const cachedValue = getCachedValue(`elementYPosition-${widgetId}`);

          if (cachedValue) {
            return parseInt(String(cachedValue), 10);
          }

          const value = placeholder ? placeholder.offsetTop : 0;

          cacheValue(`elementYPosition-${widgetId}`, value);
          return parseInt(String(value), 10);
        }
        return null;
      })(),
      paywallStatus:
        (typeof window !== 'undefined' &&
          window.FUTR &&
          window.FUTR.Kiosq &&
          window.FUTR.Kiosq.hasBarrier) ||
        null,
    },
    // Ensure we only send data for 1 deal for click events (deal was provided)
    products: (deal ? [deal] : deals).map((deal) => {
      return {
        matchId: deal?.id || null,
        merchant: {
          id: deal?.merchant?.id ?? null,
          name: deal?.merchant?.name,
          url: deal?.merchant?.url,
          network: deal?.an,
        },
        model: ((): Model => {
          const modelInfo: ModelExtra | null =
            dealData.modelInfo && dealData.modelInfo[deal.model_id]
              ? dealData.modelInfo[deal.model_id]
              : null;

          return {
            id: deal.model_id || null,
            brand: modelInfo && modelInfo.brand,
            name: modelInfo && modelInfo.model_name ? modelInfo.model_name : null,
            parent:
              modelInfo && modelInfo.parents && modelInfo.parents[0]
                ? modelInfo.parents[0].name
                : null,
          };
        })(),
        currencyIso: deal?.offer?.currency_iso,
        price:
          deal?.product_type && deal.product_type !== 200 && deal?.offer?.price
            ? parseFloat(deal.offer.price)
            : 0,
        formattedPrice: ((): string => {
          if (deal?.product_type && deal.product_type !== 200 && deal?.offer) {
            return (
              getFormattedPrice({
                price: deal.offer.price,
                currencyIso: deal.offer.currency_iso,
                showOfferLabel: false,
                type: getPriceType(deal),
                locale: language,
                translate: () => '',
                showPriceRounded: 'no',
              }) || ''
            );
          }
          return '';
        })(),
        wasPrice: deal?.offer?.was_price ?? null,
        serviceProvider: deal?.contract?.network ?? null,
        name: deal?.offer?.name,
        labels: (deal?.labels_formatted || []).map((label: Label) => {
          return {
            type: label.type,
            value: label.display_value,
          };
        }),
        promos: getFlagPromos(deal).map((promo: Promo) => {
          return {
            type: promo?.type,
            value: promo?.value,
          };
        }),
        preorder: deal?.offer?.preorder ?? false,
        url: deal?.offer?.link,
        customTrackingId: deal?.offer?.custom_tracking_id
          ? String(deal.offer.custom_tracking_id)
          : null,
        startDate: deal?.start_time ?? null,
        endDate: deal?.end_time ?? null,
        scope:
          (deal && (deal['promos'] || []).find((p) => p.type === 'scope')?.display_value) ?? null,
        type: getGATitle(deal.product_type),
        timeRemaining: ((): number | null => {
          // If there is an end time return the milliseconds remaining
          if (deal?.end_time) {
            const endDate = new Date(0);
            endDate.setUTCSeconds(deal.end_time);
            const currentDate = new Date();
            return endDate.getTime() - currentDate.getTime();
          }
          return null;
        })(),
        voucherCodeString: voucherCodeString?.display_value ?? null,
      };
    }),
    reviews: reviews.map((review) => {
      return {
        code: review.code_value,
        count: review.review_count,
        score: review.review_score,
      };
    }),
  };

  // Update the default data for event, products or reviews if specified
  // This is to support non standard analytics, e.g. the seasonal widget
  if (event) {
    gaData.event = {
      ...gaData.event,
      ...event,
    };
  }

  if (product) {
    if ((gaData.products || []).length === 0) {
      gaData.products = [product];
    } else {
      gaData.products = gaData.products.map((p) => {
        return {
          ...p,
          ...product,
        };
      });
    }
  }

  return gaData;
};
