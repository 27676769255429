import { ActionTypes, EmailVerificationStates, UserState, UserStateAction } from '../types';

export const getInitialState = (): UserState => {
  return {
    user: null,
    sessionId: '',
    isSessionLoaded: false,
    emailVerificationState: EmailVerificationStates.UNSENT,
    signupForm: {
      error: '',
      isLoading: false,
      email: '',
      terms: false,
    },
  };
};

export const reducer = (state: UserState, action: UserStateAction): UserState => {
  switch (action.type) {
    case ActionTypes.GET_SESSION: {
      const user = window.FUTR?.Kiosq?.User
        ? {
            id: window.FUTR.Kiosq.User.sub ? String(window.FUTR.Kiosq.User.sub) : null,
            email: window.FUTR.Kiosq.User.email ? String(window.FUTR.Kiosq.User.email) : null,
            isVerified: !!window.FUTR.Kiosq.User['email-verified'],
          }
        : null;
      return {
        ...state,
        user,
        sessionId: action.payload,
        isSessionLoaded: true,
        signupForm: {
          ...state.signupForm,
          isLoading: false,
        },
      };
    }
    case ActionTypes.SIGNUP_INIT: {
      return {
        ...state,
        signupForm: {
          ...state.signupForm,
          isLoading: true,
        },
      };
    }
    case ActionTypes.FORM_ERROR: {
      return {
        ...state,
        signupForm: {
          ...state.signupForm,
          error: action.payload.error,
          isLoading: false,
        },
      };
    }
    case ActionTypes.FORM_INPUT_CHANGE: {
      return {
        ...state,
        signupForm: {
          ...state.signupForm,
          ...action.payload,
        },
      };
    }
    case ActionTypes.SET_EMAIL_SENDING: {
      return {
        ...state,
        emailVerificationState: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
