import React from 'react';

import { createRoot } from 'react-dom/client';

import { MasterWidgetMain } from '../../../core/components/MasterWidget';
import { WidgetResult } from '../../../core/hooks/useAnalytics';
import { WidgetId } from '../../../core/types/Widget';
import { createAiredaleFeedsGetter } from '../../../sharedModules/api/airedale/createAiredaleFeedsGetter';
import { getJSON, postForm } from '../../../sharedModules/api/apiUtils';
import { createModelSuggestionsGetter } from '../../../sharedModules/api/searchApi/createModelSuggestionsGetter';
import { createSeasonalResponseGetter } from '../../../sharedModules/api/searchApi/createSeasonalResponseGetter';
import { createWidgetResponseGetter } from '../../../sharedModules/api/searchApi/createWidgetResponseGetter';
import { createWidgetResponsePost } from '../../../sharedModules/api/searchApi/createWidgetResponsePost';
import getComponent from '../../../sharedModules/getComponent';
import { hideLinkedElements } from '../../../sharedModules/hideLinkedElements';
import { setDataViewports } from '../../../sharedModules/setDataViewPorts';
import { enumFromStringValue } from '../../../utils/enumFromStringValue';
import getRenderType from '../../../utils/getRenderType';
import getUrlPath from '../../utils/getUrlPath';
import type { IProcessedWidget } from '../hawk/processWidgets/processWidget';

import { IRenderWidgetProps } from './model';

export const renderWidget = async (
  renderWidgetProps: IRenderWidgetProps,
): Promise<IProcessedWidget | null> => {
  const { el } = renderWidgetProps;
  // Regardless of widgetType, use a button widget type inside a table - HAWK-842
  const attributes = el.dataset;
  if (el.parentNode && el.parentNode.nodeName === 'TD') {
    attributes.widgetType = 'button';
  }

  const widgetType = enumFromStringValue(WidgetId, attributes.widgetType || '') || ('' as WidgetId);
  let widgetModule = await getComponent(widgetType);
  const component = (await widgetModule?.load()) as unknown as {
    default: React.FunctionComponent;
  };

  if (!component?.default?.getInitialiserProps) {
    return null;
  }

  const { props, type, widgetWrapper } = await component.default.getInitialiserProps({
    renderProps: renderWidgetProps,
    attributes,
    getWidgetResponse: createWidgetResponseGetter(getJSON),
    getSeasonalResponse: createSeasonalResponseGetter(getJSON),
    getAiredaleFeeds: createAiredaleFeedsGetter(getJSON),
    getModelSuggestions: createModelSuggestionsGetter(getJSON),
    postForWidgetResponse: createWidgetResponsePost(postForm),
    editorial:
      getRenderType(el.getAttribute('data-render-type') || '', 'editorial') === 'editorial',
    defaultParams: {
      articleType: renderWidgetProps.articleType,
      articleCategory: renderWidgetProps.articleCategory,
      cacheBust: renderWidgetProps.cacheBust ? new Date().getTime() : null,
      now: renderWidgetProps.now,
      battle: window.VAN && window.VAN.abTest && window.VAN.abTest.battleId,
    },
    url: getUrlPath(),
    origin: 'widgets-clientside',
    dataLinkMerchant: {
      id: el.getAttribute('data-merchant-id') ? Number(el.getAttribute('data-merchant-id')) : null,
      name: el.getAttribute('data-merchant-name'),
      url: el.getAttribute('data-merchant-url'),
      network: el.getAttribute('data-merchant-network'),
    },
  });
  if (type === WidgetResult.SUCCESS) {
    /**
     * Render the widget and hide the linked elements (e.g. POM or M101 widget).
     * We only want to do this when a widget is actually rendered
     * The linked elements themselves use 'data-hawk-fallback-ids' to allow having
     * multiple widgets links
     * We need to link multiple widgets to avoid an edge case with the stacked product
     * title on womanandhome (HAWK-667)
     */
    hideLinkedElements(props.placeholder);

    setDataViewports(props.placeholder);
  }

  const { widgetTypeComponent } = props;
  // If widget changes we need to get correct component
  if (widgetTypeComponent !== widgetType) {
    widgetModule = await getComponent(widgetTypeComponent);
  }

  const renderFallback =
    type === WidgetResult.SKIPPED ||
    (type === WidgetResult.MISSING &&
      widgetTypeComponent !== WidgetId.PROMOTION_FALLBACK &&
      widgetTypeComponent !== WidgetId.GENERIC_FALLBACK);

  const root = createRoot(el);
  root.render(
    <MasterWidgetMain
      {...props}
      component={widgetModule}
      widgetWrapper={widgetWrapper}
      renderFallback={renderFallback}
      serverSideHtml={el.innerHTML}
      type={type}
    />,
  );

  // We need to keep track about processed widgets
  if (type !== WidgetResult.SKIPPED) {
    return {
      widgetId: props.widgetId,
      widgetType: props.widgetTypeComponent,
      props,
    };
  }

  return null;
};
