import React from 'react';

import { DialogOverlay } from '../../../DialogOverlay';
import { useHawkWidgetContext } from '../../../HawkWidget/HawkWidgetProviderContext';

import styles from './styles/common.css';

interface ErrorProps {
  text: string;
  title?: string;
}

export const Error: React.FC<ErrorProps> = ({ text, title }) => {
  const { setPopupDeal } = useHawkWidgetContext();

  const handleCloseDialog = (): void => {
    setPopupDeal(null);
  };

  return (
    <DialogOverlay closeDialog={handleCloseDialog}>
      <div className={styles.container}>
        <div className={styles.main}>
          {title ? <h3 className={styles.title}>{title}</h3> : null}
          <p className={styles.text}>{text}</p>
        </div>
      </div>
    </DialogOverlay>
  );
};
