import { QueryParams } from '../../../utils/encodeQueryString';
import { getSearchAPIEndpoint } from '../../../utils/getSearchAPIEndpoint';
import { ApiResponse, PostForm } from '../apiUtils';

import { WidgetResponse } from './createWidgetResponseGetter';

export const createWidgetResponsePost = (postForm: PostForm<WidgetResponse>) => {
  return (formData: FormData, params?: QueryParams): Promise<ApiResponse<WidgetResponse>> => {
    return postForm(`${getSearchAPIEndpoint()}/widget.php`, formData, params);
  };
};

export type WidgetResponsePost = ReturnType<typeof createWidgetResponsePost>;
