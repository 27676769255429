import {
  apstagAttributes,
  defaultApstagAttributes,
  getAmazonApstagAttributesForHref,
} from '../../../../../sharedModules/apstag/getAttributes';
import { getAsinBidStore } from '../../../../../sharedModules/apstag/integration';
import { isFullUrl } from '../../../../utils/isFullUrl';
import { TargetEnum, TrackedLinkAttributesProp } from '../model';

export const getTarget = (siteHostname: string, linkUrl?: string): string => {
  if (linkUrl && isFullUrl(linkUrl)) {
    try {
      const link = new URL(linkUrl);
      const linkHostname = link.hostname.startsWith('www.')
        ? link.hostname.slice(4)
        : link.hostname;

      return siteHostname === linkHostname ? TargetEnum.SELF : TargetEnum.BLANK;
    } catch {
      return TargetEnum.BLANK;
    }
  }
  // It's not url it's a relative path, which targets the same site
  return TargetEnum.SELF;
};

export const addExternalLinkRelations = (
  attributes: TrackedLinkAttributesProp,
): TrackedLinkAttributesProp => {
  return {
    ...attributes,
    rel: 'sponsored noopener',
  };
};

export const addNcaAttributes = (
  attributes: TrackedLinkAttributesProp,
): TrackedLinkAttributesProp => {
  const hrefAttributes = getAmazonApstagAttributesForHref(attributes.href ?? '');
  const isApiAsinTrustworthy =
    attributes[apstagAttributes.asin]?.startsWith('B0') || !hrefAttributes[apstagAttributes.asin];

  const asinStore =
    getAsinBidStore(attributes[apstagAttributes.asin]) ||
    getAsinBidStore(hrefAttributes[apstagAttributes.asin]);

  if (asinStore?.isRequestMade) {
    // We have already done a bid request for that asin
    // as such we should use that rewritten url if available
    // otherwise we want to just use the api href
    attributes = {
      ...attributes,
      href: asinStore.href || attributes.href,
      ...defaultApstagAttributes,
    };
  } else if (
    isApiAsinTrustworthy &&
    hrefAttributes[apstagAttributes.tag] &&
    hrefAttributes[apstagAttributes.ascsubtag]
  ) {
    // We trust the data from the API more than what we harvest from the url
    // but only if the API asin starts with B0 showing that it has an asin structure
    // and we have a tag and subtag
    attributes = {
      ...hrefAttributes,
      ...attributes,
    };
  } else if (
    hrefAttributes[apstagAttributes.asin] &&
    hrefAttributes[apstagAttributes.tag] &&
    hrefAttributes[apstagAttributes.ascsubtag]
  ) {
    attributes = {
      ...attributes,
      ...hrefAttributes,
    };
  } else {
    attributes = {
      ...attributes,
      ...defaultApstagAttributes,
    };
  }

  return attributes;
};
