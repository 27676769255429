import type { Site } from '../../../core/modules/sites/types/Site';
import type { Deal } from '../../../core/types/Deal';
import { getSearchAPIEndpoint } from '../../../utils/getSearchAPIEndpoint';
import { getRequestId } from '../../getRequestId/getRequestId';
import { getJSON } from '../apiUtils';

interface RedeemResponse {
  code: string | null;
  product_key: string | null;
  request_id: number;
  message: string;
  area: string;
}

export const redeemUniqueCodePerDeal = async (
  site: Site,
  area: string,
  deal: Deal,
): Promise<string> => {
  const response = await getJSON<RedeemResponse>(`${getSearchAPIEndpoint(site)}/redeem.php`, {
    site: site.id,
    area,
    request_id: getRequestId(),
    match_id: deal?.match_id,
  });
  if (response.ok && response.data.code) {
    return response.data.code;
  }
  throw new Error('No code returned from unique code fetch');
};
