export enum ProductTypes {
  multimedia = 3500,
  broadband = 3000,
  subscription = 2500,
  contracts = 2000,
  deals = 1000,
  accessories = 500,
  unique_code = 431,
  vouchers = 430,
  rewards = 429,
  newsletter = 425,
  offer_deals = 420,
  offers = 400,
  fallback = 300,
  similar = 200,
  none = 0,
}
