import React from 'react';

import { Consumer } from '../HawkWidget/HawkWidgetProviderContext';

import getSiteLogo from './getSiteLogo';

interface Props {
  className?: string;
  height?: number;
  width?: number;
}

const SiteLogo = ({ className, width = 60, height = 30 }: Props): JSX.Element => (
  <Consumer>
    {(value): JSX.Element | null => {
      const {
        site,
        genericSharedComponents: { LazyImage },
      } = value;

      const imageUrl = getSiteLogo(site);

      if (imageUrl) {
        return (
          <LazyImage
            {...{
              src: imageUrl,
              alt: site && site.label,
              className: `site-logo-${className}`,
              width,
              height,
            }}
          />
        );
      }
      return null;
    }}
  </Consumer>
);

export default SiteLogo;
